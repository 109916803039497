import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.typography.button,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
    marginTop: "3%",
  },
  field: {
    width: "90%",
    marginTop: "2%",
    marginBottom: "2%",

    margin: "0 10px",
  },
  formControl: {
    // margin: theme.spacing(1),
    marginTop: "10px",
    minWidth: "90%",
  },
  formContainer: {
    // width: "100vw",
    // margin:'5% 0',
    height: "100%",
    backgroundColor: "#fff",
  },
  textarea: {
    width: "82%",
    marginBottom: "2%",
  },
  question: {
    textAlign: "start",
    padding: theme.spacing(1),
    marginLeft: "8%",
  },
  button: {
    margin: "1%",
    backgroundColor: "#2699FB",
    color: "#fff",
    fontSize: "12px",
    borderRadius: "10px",
    marginBottom: "3%",
    "&:hover": {
      backgroundColor: "#001F53",
      color: "white",
    },
  },
  currentInfo: {
    fontSize: "16px",
    marign: "10px",
    fontWeight: "bold",
    boxShadow: "none",
  },
  timingsAvailable: {
    margin: "1%",
    borderRadius: "15px",
    backgroundColor: "#ededed",
    // "&:focus": {
    //   backgroundColor: "black",
    //   color: "#fff",
    // },
  },
  selectedTimeButton: {
    backgroundColor: "#001F53",
    color: "#fff",
    margin: "1%",
    borderRadius: "15px",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  select: { minWidth: "90%", margin: "3%" },
}));
