// Override http config
module.exports.api = {
    url: 'https://o2ikq7lv7l.execute-api.us-east-1.amazonaws.com/QA',
};

module.exports.okta = {
    clientId: '0oaycnsg1njTlom3r0h7',
    url: 'https://tyson.oktapreview.com/',
    issuer: 'https://tyson.oktapreview.com/oauth2/default',
    redirectUri: 'https://tysoncovidtestself-scheduling-preprod.tyson.com/callback',
};

module.exports.environment = {
    environment: 'preprod'
}

module.exports.version = { number: '1.0' }
