import React from "react";
import classes from "../landingScreen/Appointment.module.css";
import Grid from "@material-ui/core/Grid";
import moment from "moment";

const RecentAppointmentInfo = (props) => {
  const convertGMTToCSTTime = (gmtTime) => {
    var m = moment.utc(gmtTime);
    m.tz("America/Chicago");
    return m.format("hh:mm A");
  };
  const convertGMTToCSTDate = (gmtTime) => {
    var m = moment.utc(gmtTime);
    m.tz("America/Chicago");
    return m.format("YYYY-MM-DD");
  };

  return (
    <>
      <Grid item xs={12} sm={12} md={12} className={classes.section}>
        <div className={classes.appointment}>
          <>
            <Grid item xs={12}>
              <span className={classes.details} justify="center">
                Current Appointment Info
              </span>
            </Grid>
            {props.info.empName !== undefined && props.info.email !== undefined ? (
              <>
                <Grid item xs={12}>
                  <span className={classes.name}>Employee Name : {props.info.empName}</span>
                </Grid>
                <Grid item xs={12}>
                  <span className={classes.name}>Employee Email ID : {props.info.email}</span>
                </Grid>
              </>
            ) : null}
            <Grid container direction="row" className={classes.gridRow}></Grid>
            {props.info.startTime !== null ? (
              <Grid container direction="row" className={classes.gridRow}>
                <Grid item xs={12}>
                  <span className={classes.name}>Current Appointment Date : {convertGMTToCSTDate(props.info.startTime)}</span>
                </Grid>

                <Grid item xs={12}>
                  <span className={classes.name}>Current Start Time : {convertGMTToCSTTime(props.info.startTime)} </span>
                </Grid>
              </Grid>
            ) : null}
          </>
        </div>
      </Grid>
    </>
  );
};
export default RecentAppointmentInfo;
