import * as actionTypes from "../actions/actionTypes";

const initialState = {
  followUpNotes: [
    {
      title: "Follow up Luis Gutierrez Appointment",
      content: "Should send another notification",
      startTime: "2021-02-27T10:00:00",
    },
  ],
  nonCompliantEmployees: [],
  followUp: [],
  allEmployees: [],
  path: "/",
  reservedSlot: [],
};

export const AdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_FOLLOWUP_NOTES:
      return {
        ...state,
        followUpNotes: action.followUpNotes,
      };
    case actionTypes.SET_NON_COMPLIANT_EMPLOYEES:
      return {
        ...state,
        nonCompliantEmployees: action.nonCompliantEmployees,
      };
    case actionTypes.SET_FOLLOWUPS:
      return {
        ...state,
        followUp: action.payload,
      };
    case actionTypes.SET_PATH:
      return {
        ...state,
        path: action.path,
      };
    case actionTypes.SET_ALL_EMPLOYEES:
      return {
        ...state,
        allEmployees: action.allEmployees,
      };
    case actionTypes.RESERVED_SLOTS:
      return {
        ...state,
        reservedSlot: action.data,
      };
    default:
      return state;
  }
};
