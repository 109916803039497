import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import FlagIcon from "@material-ui/icons/Flag";
import Paper from "@material-ui/core/Paper";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import { useStyles } from "./styling";
import MaterialTable from "material-table";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import Clear from "@material-ui/icons/Clear";
import Search from "@material-ui/icons/Search";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Check from "@material-ui/icons/Check";
import FilterList from "@material-ui/icons/FilterList";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Save from "@material-ui/icons/Save";
import Remove from "@material-ui/icons/Remove";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#00000029",
    color: "#000",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const NonCompliantTable = (props) => {
  const { nonCompliantEmployees } = props;
  const classes = useStyles();

  return (
    <>
      <MaterialTable
        title={"Non Compliant Employees"}
        key={nonCompliantEmployees.empID}
        data={nonCompliantEmployees}
        columns={[
          { title: "Employee Name", field: "fullName", render: (nonCompliantEmployees) => <span className="cursor-pointer font-family">{nonCompliantEmployees.fullName}</span> },
          { title: "Employee Email", field: "email", render: (nonCompliantEmployees) => <span className="cursor-pointer font-family">{nonCompliantEmployees.email}</span> },
        ]}
        icons={{
          Check: Check,
          Clear: Clear,
          DetailPanel: ChevronRight,
          Export: SaveAlt,
          Filter: FilterList,
          FirstPage: FirstPage,
          LastPage: LastPage,
          NextPage: ChevronRight,
          PreviousPage: ChevronLeft,
          Search: Search,
          ThirdStateCheck: Remove,
          Edit: Edit,
          Delete: Delete,
          SaveAlt: SaveAlt,
          Save: Save,
          SortArrow: ArrowDownwardIcon,
          NotificationImportantIcon: NotificationImportantIcon,
        }}
        actions={[
          {
            icon: NotificationImportantIcon,
            tooltip: "Send Reminder",
            onClick: (event, row) => alert("Send Reminder Email Notification"),
          },
        ]}
        options={{
          pageSize: 5,
          pageSizeOptions: [5, 10, 50],
          paging: true,
          sorting: true,
          search: true,
          headerStyle: { fontWeight: "bold" },
          actionsColumnIndex: -1,
          emptyRowsWhenPaging: false,
        }}
      />
    </>
    // <>
    //   <TableContainer TableContainer className={classes.tableContainer} component={Paper}>
    //     <Table>
    //       <TableHead>
    //         <TableRow>
    //           <StyledTableCell align="center">Employee Name</StyledTableCell>
    //           <StyledTableCell align="center">Email</StyledTableCell>
    //           <StyledTableCell align="center">Actions</StyledTableCell>
    //         </TableRow>
    //       </TableHead>
    //       <TableBody>
    //         {nonCompliantEmployees.length > 0 ? (
    //           <>
    //             {nonCompliantEmployees.map((row) => (
    //               <StyledTableRow StyledTableRow key={row.empID} className={classes.row}>
    //                 <StyledTableCell align="center">{row.fullName}</StyledTableCell>
    //                 <StyledTableCell align="center">{row.email}</StyledTableCell>
    //                 <StyledTableCell align="center">
    //                   <NotificationImportantIcon onClick={() => alert("Send Reminder Email Notification")} style={{ marginRight: "2px" }} />
    //                   {/* <FlagIcon onClick={() => alert("Flag API")} style={{ marginRight: "2px" }} /> */}
    //                 </StyledTableCell>
    //               </StyledTableRow>
    //             ))}
    //           </>
    //         ) : undefined}
    //       </TableBody>
    //     </Table>
    //     {nonCompliantEmployees.length == 0 ? "No Records Available" : ""}
    //   </TableContainer>
    // </>
  );
};

export default NonCompliantTable;
