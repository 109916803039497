import * as actionTypes from "../actions/actionTypes";

const initialState = {
  loading: false,
  possibleTimeRanges: [],
  appointments: [],
  callApiFromCalendar: false,
  blockedTimeRanges: [],
};

export const AppointmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_AVAILABLE_TIME_RANGES:
      return {
        ...state,
        possibleTimeRanges: action.timeRanges,
      };
    case actionTypes.SET_BLOCKED_TIME:
      return {
        ...state,
        blockedTimeRanges: action.timeRanges,
      };
    case actionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case actionTypes.SET_APPOINTMENTS:
      return {
        ...state,
        appointments: action.appointments,
        callApiFromCalendar: true,
      };
    default:
      return state;
  }
};
