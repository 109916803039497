import React, { useState, useEffect } from "react";
import HistoryCalendar from "./calendar";
import Grid from "@material-ui/core/Grid";
import Employees from "./employees";
import AllEmployees from "./allEmployees";
import Button from "@material-ui/core/Button";
import { useStyles } from "./styling";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import FollowUpCalendar from "./followUpCalendar";

const AdminPanel = (props) => {
  const employee = useSelector((state) => state.employee.employeeOktaInformation);

  const history = useHistory();
  const [tabPosition, setTabPosition] = useState(1);

  const [direction, setDirection] = useState();

  useEffect(() => {
    if (employee.CovidSelfTestScheduling && employee.CovidSelfTestScheduling[0] !== "CovidSelfTestAdmin") {
      history.push("/");
      return;
    }
  }, [employee.email]);

  const classes = useStyles();
  const updateWidthAndHeight = () => {
    if (window.innerWidth < 599) {
      setDirection("column");
    }
    if (window.innerWidth > 600) {
      setDirection("row");
    }
  };
  useEffect(() => {
    updateWidthAndHeight();
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  }, []);

  const widthOfList = () => {
    if (tabPosition === 4 || tabPosition === 2) {
      return 12;
    } else if (tabPosition === 3) return 0;
    else return 7;
  };
  return (
    <Grid container direction={direction}>
      <Grid item md={2} sm={6}>
        <Button borderRadius={0} onClick={() => setTabPosition(1)} className={tabPosition === 1 ? classes.tabButtonActive : classes.tabButton}>
          Appointments
        </Button>
      </Grid>
      <Grid item md={3} sm={6}>
        <Button borderRadius={0} onClick={() => setTabPosition(2)} className={tabPosition === 2 ? classes.tabButtonActive : classes.tabButton}>
          Pending appointments
        </Button>
      </Grid>
      <Grid item md={2} sm={6}>
        <Button borderRadius={0} onClick={() => setTabPosition(3)} className={tabPosition === 3 ? classes.tabButtonActive : classes.tabButton}>
          Follow up
        </Button>
      </Grid>
      <Grid item md={3} sm={6}>
        <Button borderRadius={0} onClick={() => setTabPosition(4)} className={tabPosition === 4 ? classes.tabButtonActive : classes.tabButton}>
          Non Compliant
        </Button>
      </Grid>
      <Grid item md={2} sm={6}>
        <Button borderRadius={0} onClick={() => setTabPosition(5)} className={tabPosition === 5 ? classes.tabButtonActive : classes.tabButton}>
          All employees
        </Button>
      </Grid>
      <Grid container direction="row">
        <Grid item xs={12} sm={12} md={5} style={{ marginTop: "1%", padding: "0 1%", alignContent: "center" }}>
          {tabPosition !== 1 ? undefined : <HistoryCalendar tabPosition={tabPosition} />}{" "}
        </Grid>

        {tabPosition === 3 ? (
          <Grid item xs={10} sm={10} md={10} style={{ marginLeft: "10%" }}>
            <FollowUpCalendar tabPosition={tabPosition} />
          </Grid>
        ) : undefined}

        {tabPosition !== 5 ? (
          <Grid item xs={12} sm={12} md={widthOfList()} style={{ marginTop: "1%", padding: "0 1%", alignContent: "center" }}>
            <Employees tabPosition={tabPosition} />
          </Grid>
        ) : undefined}
        {tabPosition === 5 ? (
          <Grid item xs={12} sm={12} md={12} style={{ marginTop: "1%", padding: "0 1%", alignContent: "center" }}>
            <AllEmployees />
          </Grid>
        ) : undefined}
      </Grid>
    </Grid>
  );
};

export default AdminPanel;
