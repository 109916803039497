import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { useStyles } from "./styling";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import Search from "@material-ui/icons/Search";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import FilterList from "@material-ui/icons/FilterList";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Grid from "@material-ui/core/Grid";
import Zoom from "@material-ui/core/Zoom";
import Tooltip from "@material-ui/core/Tooltip";
import { Checkbox } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Loader from "../images/loading.gif";
import { getAllEmployees } from "../../store/actions";

const mapDispatchToProps = (dispatch) => {
  return {
    onGetAllEmployees: () => dispatch(getAllEmployees()),
  };
};

const mapStateToProps = (state) => {
  return {
    allEmployees: state.admin.allEmployees,
  };
};

const AllEmployees = (props) => {
  const { allEmployees, onGetAllEmployees } = props;
  const [loading, setLoading] = useState(false);
  const [noEmailChecked, setNoEmailChecked] = useState(false)
  const classes = useStyles();

  const loadingImage = (
    <div
      style={{
        backgroundColor: "#fff",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "75vh",
        marginTop: "-40px",
        padding: "0px",
      }}
    >
      <img src={Loader} alt="loading" height="100vh" />
    </div>
  );

  useEffect(() => {
    setLoading(true);
    onGetAllEmployees().then((result) => {
      setLoading(false);
    });
  }, []);

  const handleChange = (event) => {
    console.log("made it here");
    setNoEmailChecked(event.target.checked)
  };

  const WhiteCheckbox = withStyles({
    root: {
      color: "#FFFFFF",
      "&$checked": {
        color: "#FFFFFF",
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  let columns = (data) => {
    return [
      {
        title: "Employee Name",
        field: "empName",
        render: (data) => (
          <span>
            {data.empName} {data.empLastName}
          </span>
        ),
      },
      {
        title: "Employee Email",
        field: "email",
        render: (data) => <span>{data.email}</span>,
      },
      {
        title: "Employee Phone Number",
        field: "phoneNum",
        render: (data) => <span>{data.phoneNum}</span>,
      },
      {
        title: "Employee Number",
        field: "empNb",
        render: (data) => <span>{data.empNb}</span>,
      },
      {
        title: "Needs appointment?",
        render: (data) => <span>{data.calendarID === null || data.workflowStatus === 7 ? "Appointment needs to be scheduled" : ""}</span>,
      },
    ];
  };
  //
  return (
    <>

      {loading ? (
        loadingImage
      ) : allEmployees ? (
        <>
          <Grid item xs={2} className={classes.addMember} style={{ marginBottom: "10px" }}>
            <Tooltip style={{ padding: 5, color: "white" }} TransitionComponent={Zoom} arrow title="display employees without email addresses">
              <FormControlLabel control={<WhiteCheckbox checked={noEmailChecked} onChange={handleChange} name="checkedA" color="white" />} label="Employees Without Email" />
            </Tooltip>
          </Grid>
          <MaterialTable
            title="All employees"
            key={allEmployees.empID}
            data={noEmailChecked ? allEmployees.filter(emp => !emp.email || emp.email === "" || emp.email === "null") : allEmployees}
            columns={columns(allEmployees)}
            icons={{
              Export: SaveAlt,
              Filter: FilterList,
              FirstPage: FirstPage,
              LastPage: LastPage,
              NextPage: ChevronRight,
              PreviousPage: ChevronLeft,
              Search: Search,
              SortArrow: ArrowDownwardIcon,
            }}
            options={{
              pageSize: 5,
              pageSizeOptions: [10, 20, 50],
              paging: true,
              sorting: true,
              search: true,
              headerStyle: { fontWeight: "bold" },
              actionsColumnIndex: -1,
              emptyRowsWhenPaging: false,
            }}
          />
        </>
      ) : undefined}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AllEmployees);
