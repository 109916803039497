import React, { useEffect } from "react";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import { useStyles } from "./styling";
import RecentAppointment from "./recentAppointment";
import { onUpdatePath } from "../../store/actions";
import Loader from "../images/loading.gif";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { connect } from "react-redux";
import { getEmployeeTestCases, onSetLoading, getEmployee, onGetEmployeeOktaInformation, postEmployeeRoleToBackend } from "../../store/actions/index";

const mapDispatchToProps = (dispatch) => {
  return {
    onPathChange: (path) => dispatch(onUpdatePath(path)),
    getEmployeeTestCases: (value) => dispatch(getEmployeeTestCases(value)),
    onLoading: (value) => dispatch(onSetLoading(value)),
    onGetEmployee: () => dispatch(getEmployee()),
    onGetEmployeeOktaInformation: (oktaInformation) => dispatch(onGetEmployeeOktaInformation(oktaInformation)),
    postEmployeeRoleToBackend: () => dispatch(postEmployeeRoleToBackend()),
  };
};
const mapStateToProps = (state) => {
  return {
    employeeTestCases: state.employee.employeeTestCases,
    employee: state.employee.employee,
    loading: state.appointment.loading,
    employeeOktaInformation: state.employee.employeeOktaInformation,
    currentUser: state.employee.currentUser,
  };
};

const LandingPage = (props) => {
  const { employeeTestCases, onPathChange, getEmployeeTestCases, employee, onLoading, loading, onGetEmployee, onGetEmployeeOktaInformation, employeeOktaInformation, currentUser, postEmployeeRoleToBackend } = props;
  const classes = useStyles();
  const [width, setWidth] = React.useState("30vw");
  const [direction, setDirection] = React.useState();
  const history = useHistory();
  let path = window.location.pathname;
  let latestAppointment = employeeTestCases[employeeTestCases.length - 1];
  const updateWidthAndHeight = () => {
    if (window.innerWidth < 750) {
      setWidth("80vw");
      setDirection("column");
    }
    if (window.innerWidth > 751) {
      setWidth("30vw");
      setDirection("row");
    }
  };

  useEffect(() => {
    updateWidthAndHeight();
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  });

  useEffect(() => {
    // let oktaUser = JSON.parse(window.sessionStorage.getItem("user"));
    onLoading(true);
    if (currentUser.email) {
      postEmployeeRoleToBackend().then((result) => {
        onGetEmployee("").then((result) => {
          getEmployeeTestCases().then((res) => {
            if (!employeeOktaInformation) {
              return;
            }
            if (!employeeOktaInformation.CovidSelfTestScheduling) {
              return;
            }
            if (employeeOktaInformation.CovidSelfTestScheduling.length === 0) {
              return;
            }

            if (currentUser.isAdmin) {
              history.push("/admin");
            }
            onLoading(false);
            // });
          });
        });
      });
    }
  }, [currentUser.email]);

  useEffect(() => {
    onPathChange(path);
  }, [history]);

  return (
    <>
      {employee.email !== undefined ? (
        <div>
          {currentUser.isRegularUser && currentUser.isValid ? (
            <div>
              <RecentAppointment />
            </div>
          ) : null}
          <div style={{ display: "flex", flexDirection: direction, justifyContent: "space-around" }}>
            {(latestAppointment !== undefined && latestAppointment.startTime == null && latestAppointment.statusID !== 4 && latestAppointment.statusID !== 5) || latestAppointment == undefined ? (
              currentUser.isValid ? (
                !currentUser.isAdmin && !currentUser.isHR ? (
                  <Card className={classes.root} style={{ minWidth: width }} variant="outlined">
                    <CardContent>
                      <Typography variant="h5" component="h2">
                        Schedule Appointment
                      </Typography>
                    </CardContent>
                    <CardActions className={classes.action}>
                      <Button size="small" className={classes.button} onClick={() => history.push("/schedule")}>
                        <ScheduleIcon />
                        &nbsp; Schedule
                      </Button>
                    </CardActions>
                  </Card>
                ) : undefined
              ) : undefined
            ) : undefined}

            {employeeOktaInformation ? (
              currentUser.isHR ? (
                <Card className={classes.root} style={{ minWidth: width }} variant="outlined">
                  <CardContent>
                    <Typography variant="h5" component="h2">
                      Flagged Employees
                    </Typography>
                  </CardContent>
                  <CardActions className={classes.action}>
                    <Button size="small" className={classes.button} onClick={() => history.push("/flaggedEmployees")}>
                      See List
                    </Button>
                  </CardActions>
                </Card>
              ) : undefined
            ) : undefined}
          </div>
        </div>
      ) : (
        <div
          style={{
            backgroundColor: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "82vh",
            margin: "0px",
            padding: "0px",
          }}
        >
          <img src={Loader} alt="loading" height="100vh" />
        </div>
      )}
    </>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
