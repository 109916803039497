import React from "react";
import "./index.css";
import Logo from "./logo.png";
const Footer = () => {
  return (
    <footer className="footer">
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
        <img src={Logo} width={"30px"} height={"30px"} style={{ marginRight: "10px", paddingTop: "10px" }}></img>
        <p>Tyson Foods</p>
      </div>
    </footer>
  );
};
export default Footer;
