let hostnameVal = window.location.hostname;
let envConfig = {};
//this switch function will verify in wich env is the app executing
//and will load all the necessary info for okta login.
// console.log("hostnameVal: ", hostnameVal)
switch (hostnameVal) {
  case "localhost":
    envConfig = require("./test");
    break;
  case "tysoncovidtestself-scheduling-dev.tyson.com":
    envConfig = require("./dev");
    break;
  case "tysoncovidtestself-scheduling-preprod.tyson.com":
    envConfig = require("./qa");
    break;
  case "tysoncovidtestself-scheduling.tyson.com":
    envConfig = require("./prod");
    break;
  case "d3hy9364vabnd7.cloudfront.net":
    envConfig = require("./dev");
    break;
  default:
    envConfig = require("./test");
}
module.exports = envConfig;
