import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./store/reducers";
import { authClient, loginWithRedirect, scopes } from "./services/auth-instance";

const store = rootReducer && createStore(rootReducer, applyMiddleware(thunk));

const enableAuth = true;

if (enableAuth) {
  authClient.session.exists().then((authStatus) => {
    if (authStatus) {
      window.history.replaceState(null, null, window.location.pathname);
      authClient.token
        .getWithoutPrompt({
          ...scopes,
          sessionToken: authClient.session.get(),
        })
        .then((response) => {
          // console.log("okta response - ", response);
          authClient.tokenManager.clear();
          authClient.tokenManager.add("accessToken", response[0]);
          authClient.tokenManager.add("idToken", response[1]);
          window.sessionStorage.setItem("accessToken", response[0]);
          return authClient.token.getUserInfo(JSON.parse(window.sessionStorage.getItem("okta-token-storage")).accessToken).then((user) => {
            window.sessionStorage.setItem("user", JSON.stringify(user));
            return user
          });
        })
        .then((user) => {
          ReactDOM.render(
            <React.StrictMode>
              <Provider store={store}>
                <App oktaInformation={user} />
              </Provider>
            </React.StrictMode>,
            document.getElementById("root")
          );
        })
        .catch((err) => { });
    } else {
      loginWithRedirect();
    }
  });
} else {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>,
    document.getElementById("root")
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
