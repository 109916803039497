import React from "react";
import { Button } from "@material-ui/core";

const TimeSelector = (props) => {
  let styleArray = [];
  let styleArrayUnBlock = [];
  const [buttonStyle, setButtonStyle] = React.useState(styleArray);
  const [buttonStyleUnBlock, setButtonStyleUnBlock] = React.useState(styleArrayUnBlock);

  let path = window.location.pathname;
  let text = path == "/unblock" ? "Looks like there is no reserved time slot!" : "Sorry! There is no availability today";
  let date = props.dateObjToString(props.appointmentDate);
  const time = [];

  if (path == "/block") {
    const modifyStyle = (slotID, styleArray) => {
      let dummyArray = [...buttonStyle];
      if (dummyArray.length > 0) {
        dummyArray.find((o) => o.buttonId == slotID).isSelected = !dummyArray.find((o) => o.buttonId == slotID).isSelected;
        setButtonStyle(dummyArray);
        let testArray = [];
        for (let i = 0; i < dummyArray.length; i++) {
          if (props.timeRanges[i].slotID == dummyArray[i].buttonId && dummyArray[i].isSelected) {
            testArray.push(props.timeRanges[i]);
          }
        }

        props.slots.splice(0, props.slots.length, ...testArray);
      }
    };
    const handleAddBlockTime = (timeRange, styleArray) => {
      modifyStyle(timeRange.slotID, styleArray);
    };
    if (props.timeRanges) {
      props.timeRanges.forEach((timeRange) => {
        let obj = {};
        obj.buttonId = timeRange.slotID;
        obj.isSelected = false;
        styleArray.push(obj);

        if (timeRange.scheduleDate.includes(date)) {
          time.push(
            <Button
              key={timeRange.startTime}
              onClick={() => handleAddBlockTime(timeRange, styleArray)}
              className={buttonStyle.length > 0 && buttonStyle.find((o) => o.buttonId == timeRange.slotID).isSelected ? props.class.selectedTimeButton : props.class.timingsAvailable}
            >
              {timeRange.startTime}
            </Button>
          );
        }
      });
    }
  } else if (path == "/schedule" || path == "/reschedule") {
    const handleSettingParams = (timeRange) => {
      let dateWithoutTime = new Date(timeRange.scheduleDate);
      let addMinsToDate = dateWithoutTime.setMinutes(dateWithoutTime.getMinutes() + timeRange.mins);
      let timeWithDate = new Date(addMinsToDate).toISOString();
      props.slot(timeRange.slotID);
      props.mins(timeRange.mins);
      props.setSelectedTimeRange(timeRange);
      props.setTimeWithDate(timeWithDate);
    };
    if (props.timeRanges !== undefined) {
      props.timeRanges.forEach((timeRange) => {
        if (timeRange.scheduleDate.includes(date)) {
          time.push(
            <Button
              key={timeRange.startTime}
              onClick={() => handleSettingParams(timeRange)}
              className={
                timeRange
                  ? timeRange.startTime && props.selectedTimeRange
                    ? timeRange.startTime === props.selectedTimeRange.startTime
                      ? props.class.selectedTimeButton
                      : props.class.timingsAvailable
                    : props.class.timingsAvailable
                  : props.class.timingsAvailable
              }
            >
              {timeRange.startTime}
            </Button>
          );
        }
      });
    }
  } else if (path == "/unblock") {
    const modifyStyleUnblock = (slotID, styleArrayUnBlock) => {
      let dummyArray = [...buttonStyleUnBlock];
      if (dummyArray.length > 0 && dummyArray.find((o) => o.buttonId == slotID)) {
        dummyArray.find((o) => o.buttonId == slotID).isSelected = !dummyArray.find((o) => o.buttonId == slotID).isSelected;
        setButtonStyleUnBlock(dummyArray);
        let testArray = [];
        for (let i = 0; i < dummyArray.length; i++) {
          if (props.blockedTimeRanges[i].slotID == dummyArray[i].buttonId && dummyArray[i].isSelected) {
            testArray.push(props.blockedTimeRanges[i]);
          }
        }
        props.bookedSlots.splice(0, props.bookedSlots.length, ...testArray);
      }
    };
    const handleAddUnblockTime = (timeRange, styleArrayUnBlock) => {
      // setButtonStyleUnBlock([...styleArrayUnBlock]);
      modifyStyleUnblock(timeRange.slotID, styleArrayUnBlock);
    };
    const styleForUnBlock = (timeRange) => {
      if (buttonStyleUnBlock && buttonStyleUnBlock.length > 0) {
        if (buttonStyleUnBlock.find((o) => o.buttonId == timeRange.slotID) && buttonStyleUnBlock.find((o) => o.buttonId == timeRange.slotID).isSelected) {
          return props.class.selectedTimeButton;
        } else return props.class.timingsAvailable;
      } else return props.class.timingsAvailable;
    };
    if (props.blockedTimeRanges) {
      props.blockedTimeRanges.forEach((timeRange) => {
        let obj = {};
        obj.buttonId = timeRange.slotID;
        obj.isSelected = false;
        styleArrayUnBlock.push(obj);

        if (timeRange.startTime.substring(0, 10).includes(date)) {
          time.push(
            <Button key={timeRange.slotStartTime} onClick={() => handleAddUnblockTime(timeRange, styleArrayUnBlock)} className={styleForUnBlock(timeRange)}>
              {timeRange.slotStartTime}
            </Button>
          );
        }
      });
    }
  }

  return <>{props.loading ? "Gathering available times ..." : props.beforeToday ? "Can't select time in past" : time.length > 0 ? time : text}</>;
};

export default TimeSelector;
