import React from "react";
import NonCompliantTable from "./nonCompliantTable";
import { connect } from "react-redux";
import { getNonCompliantEmployees } from "../../store/actions/index";
import { useHistory } from "react-router-dom";

const mapDispatchToProps = (dispatch) => {
  return {
    onGetNonCompliantEmployees: () => dispatch(getNonCompliantEmployees()),
  };
};

const mapStateToProps = (state) => {
  return {
    nonCompliantEmployees: state.admin.nonCompliantEmployees,
    employee: state.employee.employee,
    employeeOktaInformation: state.employee.employeeOktaInformation,
    currentUser: state.employee.currentUser,
  };
};

const FlaggedEmployees = (props) => {
  const { onGetNonCompliantEmployees, nonCompliantEmployees, employee, employeeOktaInformation, currentUser } = props;
  const history = useHistory();

  React.useEffect(() => {
    if (currentUser.isAdmin) {
      history.push("/");
      return;
    }
  }, [currentUser.email]);

  React.useEffect(() => {
    onGetNonCompliantEmployees();
  }, []);
  return (
    <>
      {nonCompliantEmployees !== undefined ? (
        <>
          <NonCompliantTable nonCompliantEmployees={nonCompliantEmployees} />
        </>
      ) : null}
    </>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(FlaggedEmployees);
