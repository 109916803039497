import { combineReducers } from "redux";
import { AppointmentReducer as appointment } from "./appointmentsReducer";
import { EmployeeReducer as employee } from "./employeeReducer";
import { AdminReducer as admin } from "./adminReducer"

const appReducers = combineReducers({
  appointment,
  employee,
  admin,
});

const rootReducer = (state, action) => {
  return appReducers(state, action);
};

export default rootReducer;
