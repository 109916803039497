import axios from "axios";
import config from "../config"
let instance = undefined;
// let oktaToken = JSON.parse(window.sessionStorage.getItem('okta-token-storage'))
// oktaToken = oktaToken ? oktaToken.accessToken : ""

// console.log("oktaToken: ", oktaToken)

instance = axios.create({
  // baseURL: "http://employee-schedule-api.cloud.tyson.com",
  baseURL: config.api.url,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    // "Authorization": `Bearer ${oktaToken.accessToken}`,
  },
});

export default instance;
