import React, { useState } from 'react'
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css"
import { Calendar } from "@hassanmojab/react-modern-calendar-datepicker"
import { useStyles } from "./styling";

const MyCalendar = props => {
    const classes = useStyles();

    return (
        <Calendar
            value={props.appointmentDate}
            onChange={props.setAppointmentDate}
            shouldHighlightWeekends
            colorPrimary="#002b51"
            calendarClassName={classes.calendarClassName}
            calendarTodayClassName={classes.customTodayDay}
        />
    )
}

export default MyCalendar
