import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    customTodayDay: {
        color: "#002b51 !important",
        border: "1px solid #002b51 !important"
    },
    calendarClassName: {
        boxShadow: "0 1em 3em rgba(156, 136, 255,0.2)",
        zIndex: "0 !important",
        position: "relative !important",
        display: "inline-block",
    }
}));
