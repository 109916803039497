import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import { connect } from "react-redux";
import swal from "sweetalert2";
import "./custom.css";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import FollowUpForm from "./followupForm";
import { getFollowUpEmployees, cancelFollowUp } from "../../store/actions/index";
import { useStyles } from "./styling";
import { set } from "date-fns";
import AddIcon from "@material-ui/icons/Add";
import { colors } from "@material-ui/core";
import moment from "moment";

const mapStateToProps = (state) => {
  return {
    appointments: state.appointment.appointments,
    followUpNotes: state.admin.followUpNotes,
    followUp: state.admin.followUp,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getFollowUpEmployees: () => dispatch(getFollowUpEmployees()),
    cancelFollowUp: (cancelData) => dispatch(cancelFollowUp(cancelData)),
  };
};

const FollowUpCalendar = (props) => {
  const { appointments, tabPosition, followUpNotes, followUp, getFollowUpEmployees, cancelFollowUp } = props;
  const classes = useStyles();
  const [showModal, setShowModal] = React.useState(false);
  const [addOrEdit, setAddOrEdit] = React.useState("");
  const [eventData, setEventData] = React.useState("");

  // function convertUTCDateToLocalDate(date) {
  //   var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000 + 3600);
  //   var offset = date.getTimezoneOffset() / 60;
  //   var hours = date.getHours();
  //   newDate.setHours(hours - offset);
  //   console.log("Date", newDate);
  //   console.log("offset", offset);
  //   console.log("hours", hours);
  //   return newDate;
  // }
  // function convertUTCDateToLocalDate(date) {
  //   return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
  // }
  const convertGMTToCSTDate = (gmtTime) => {
    var timelagging = 5; // 5 or 6
    var utc = new Date(gmtTime);
    var cdt = new Date(utc.getTime() - 1 * 60 * 60 * 1000 * timelagging);
    return cdt;
  };
  function colorOfEvent(priority) {
    if (priority && priority.priorityLevel) {
      switch (priority.priorityLevel) {
        case "Call":
          return "orange";
        case "Attempted":
          return "blue";
        case "Completed":
          return "green";
        default:
          break;
      }
    }
  }

  let event = [];
  if (tabPosition === 3) {
    for (var i = 0; i < followUp.length; i++) {
      let obj = {};
      obj.calendarID = followUp[i].calendarID;
      obj.start = convertGMTToCSTDate(followUp[i].startTime);
      obj.eventDate = convertGMTToCSTDate(followUp[i].startTime);
      obj.bookingNotes = followUp[i].bookingnotes;
      obj.color = colorOfEvent(followUp[i].bookingnotes);
      if (followUp[i].bookingnotes) {
        obj.title = followUp[i].bookingnotes.title + " - " + followUp[i].bookingnotes.employeeName;
      }
      event.push(obj);
    }
  }

  function empName(event) {
    if (event.bookingNotes !== null && event.bookingNotes.employeeName) {
      return "<strong>Employee Name :  </strong>  " + event.bookingNotes.employeeName + "<br/>";
    } else return "";
  }
  function content(event) {
    if (event.bookingNotes !== null && event.bookingNotes.content) {
      return "<strong>Content :  </strong>  " + event.bookingNotes.content + "<br/>";
    } else return "";
  }
  function title(event) {
    if (event.bookingNotes !== null && event.bookingNotes.title) {
      return "<strong>Title :  </strong>  " + event.bookingNotes.title + "<br/>";
    } else return "";
  }
  const handleAddFollowUp = () => {
    setShowModal(true);
    setEventData("");
    setAddOrEdit("add");
  };

  return (
    <div className="App">
      <Button onClick={() => handleAddFollowUp()} className={classes.followUp}>
        <AddIcon />
        Add Follow Up
      </Button>
      <Modal style={{ display: "flex", alignItems: "center", justifyContent: "center" }} open={showModal} onClose={() => setShowModal(false)} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
        <FollowUpForm getFollowUpEmployees={getFollowUpEmployees} showModal={setShowModal} addOrEdit={addOrEdit} eventData={eventData} />
      </Modal>
      <FullCalendar
        timeZone="UTC"
        showNonCurrentDates={false}
        contentHeight="50%"
        defaultView="timeGridDay"
        header={{
          left: "prev,next",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        plugins={[dayGridPlugin, timeGridPlugin]}
        events={event}
        // eventColor={event.color}
        eventClick={function (event) {
          swal
            .fire({
              // title: event.event.title,
              title: "Follow up note",
              denyButtonText: `Delete Follow Up`,
              showDenyButton: true,
              showCancelButton: true,
              cancelButtonText: "Edit Follow Up",
              html:
                empName(event.event._def.extendedProps) +
                title(event.event._def.extendedProps) +
                content(event.event._def.extendedProps) +
                "<strong>Time:  </strong>  " +
                moment.utc(event.event.start).format("hh:mm A") +
                "<br/>" +
                "<strong> Event Date:  </strong>  " +
                event.event.start.getDate() +
                "/" +
                (event.event.start.getMonth() + 1) +
                "/" +
                event.event.start.getFullYear() +
                " " +
                event.event.start.toLocaleString("en-us", { weekday: "long" }) +
                "<br/>",
            })
            .then((result) => {
              if (result.isDenied) {
                swal
                  .fire({
                    title: "Are you sure you want to delete this followup?",
                    icon: "warning",
                    confirmButtonText: `Yes`,
                    showCancelButton: true,
                  })
                  .then((result) => {
                    if (result.isConfirmed === true) {
                      let cancelData = {
                        calendarID: event.event._def.extendedProps.calendarID,
                      };
                      cancelFollowUp(cancelData).then((status) => {
                        if (status === 200) {
                          swal.fire({
                            icon: "success",
                            title: "Followup Deleted",
                            timer: 2000,
                          });
                        } else {
                          swal.fire({
                            icon: "warning",
                            title: "Followup Not Deleted",
                            timer: 3000,
                          });
                        }
                      });
                      getFollowUpEmployees();
                    }
                  });
              } else if (result.isDismissed) {
                setShowModal(true);
                setAddOrEdit("edit");
                setEventData(event.event._def.extendedProps);
              }
            });
        }}
      />
    </div>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(FollowUpCalendar);
