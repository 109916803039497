import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
// import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Calendar from "../calendar/calendar";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import TimeSelector from "../timeSelector";
import {
  bookAppointment,
  getTimeSlots,
  blockedTimeSlots,
  onSetLoading,
  rescheduleAppointment,
  onUpdatePath,
  getEmployeeForAdmin,
  onClearEmployeeForAdminDetails,
  onClearEmployeeForAdminTestCases,
  reserveTimeSlots,
  unblockTimeSlots,
} from "../../store/actions/";
import RecentAppointment from "../landingScreen/recentAppointment";
import swal from "sweetalert2";
import RecentAppointmentInfo from "./rescheduleAppointmentInfo";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Loader from "../images/loading.gif";
import { useStyles } from "./styling";
import ScheduleIcon from "@material-ui/icons/Schedule";
import UpdateIcon from "@material-ui/icons/Update";
import moment from "moment-timezone";
import { toNumber } from "lodash";
import EventBusyIcon from "@material-ui/icons/EventBusy";
import DateRangeIcon from "@material-ui/icons/DateRange";

let today = new Date();
let dd = parseInt(String(today.getDate()).padStart(2, "0"));
let mm = parseInt(String(today.getMonth() + 1).padStart(2, "0"));
let yyyy = parseInt(today.getFullYear());

const defaultValue = {
  year: yyyy,
  month: mm,
  day: dd,
};
const mapDispatchToProps = (dispatch) => {
  return {
    onRescheduling: (data, email) => dispatch(rescheduleAppointment(data, email)),
    onBookAppointment: (data) => dispatch(bookAppointment(data)),
    onGetEmployeeForAdmin: (email) => dispatch(getEmployeeForAdmin(email)),
    onGetTimeSlots: (selectedDate, befToday) => dispatch(getTimeSlots(selectedDate, befToday)),

    onBlockedTimeSlots: (value) => dispatch(blockedTimeSlots(value)),

    onSetLoading: (value) => dispatch(onSetLoading(value)),
    onPathChange: (path) => dispatch(onUpdatePath(path)),
    onClearEmployeeForAdminDetails: () => dispatch(onClearEmployeeForAdminDetails()),
    onClearEmployeeForAdminTestCases: () => dispatch(onClearEmployeeForAdminTestCases()),
    onReserveTimeSlots: (slots, postTime) => dispatch(reserveTimeSlots(slots, postTime)),
    onUnblockTimeSlots: (calendarID) => dispatch(unblockTimeSlots(calendarID)),
  };
};

const mapStateToProps = (state) => {
  return {
    appointments: state.appointment.appointments,
    timeRanges: state.appointment.possibleTimeRanges,
    loading: state.appointment.loading,
    employee: state.employee.employee,
    employeeTestCases: state.employee.employeeTestCases,
    employeeForAdmin: state.employee.employeeForAdmin,
    employeeForAdminTestCases: state.employee.employeeForAdminTestCases,
    employeeOktaInformation: state.employee.employeeOktaInformation,
    currentUser: state.employee.currentUser,
    reservedSlots: state.admin.reservedSlot,
    blockedTimeRanges: state.appointment.blockedTimeRanges,
  };
};
const ScheduleAppointment = (props) => {
  const {
    appointments,
    timeRanges,
    employee,
    employeeTestCases,
    onBookAppointment,
    loading,
    onRescheduling,
    onPathChange,
    onSetLoading,
    onGetTimeSlots,
    onBlockedTimeSlots,
    onGetEmployeeForAdmin,
    employeeForAdmin,
    employeeForAdminTestCases,
    onClearEmployeeForAdminDetails,
    onClearEmployeeForAdminTestCases,
    employeeOktaInformation,
    currentUser,
    onReserveTimeSlots,
    reservedSlots,
    blockedTimeRanges,
    onUnblockTimeSlots,
  } = props;
  const classes = useStyles();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [address, setAddress] = useState("");
  const [empID, setEmpID] = useState("");
  const [slot, setSlot] = useState("");
  const [appointmentDate, setAppointmentDate] = useState(defaultValue);
  const [mins, setMins] = useState("");
  const [beforeToday, setBeforeToday] = useState(false);
  const [selectedTimeRange, setSelectedTimeRange] = useState(undefined);
  const [testCaseID, setTestCaseID] = useState("");
  const [reescheduleInitialDate, setReescheduleInitialDate] = useState("");
  const [timeWithDate, setTimeWithDate] = useState("");
  const [testIdentifier, setTestIdentifier] = useState("");
  // const [reservedSlots, setReservedSlots] = useState([]);
  let latestAppointment = employeeTestCases[employeeTestCases.length - 1];
  const history = useHistory();
  let path = window.location.pathname;
  var slots = [];
  var bookedSlots = [];

  useEffect(() => {
    setSelectedTimeRange(undefined);
    let date = dateObjToString(appointmentDate);
    // console.log("dataString: ", date);
    if (!validateDateIsBusinessDay()) {
      swal.fire({
        icon: "warning",
        title: "Appointments can't be on weekends",
        timer: 2000,
      });
      setAppointmentDate(defaultValue);
      return;
    }
    let comparisonDate = new Date(date + "T23:59:00");
    onBlockedTimeSlots(dateObjToString(appointmentDate), comparisonDate < today);
    setBeforeToday(comparisonDate < today);
    onGetTimeSlots(dateObjToString(appointmentDate), comparisonDate < today).then((res) => {
      onSetLoading(false);
    });

    if (!currentUser.isAdmin) {
      if (!validateReeschedulingDate()) {
        swal.fire({
          icon: "warning",
          title: "An appointment booking can't be greater than 5 business days",
          showConfirmButton: true,
        });
        setAppointmentDate(defaultValue);
      }
    }
  }, [appointmentDate]);

  useEffect(() => {
    setReescheduleInitialDate(new Date().toISOString());
    onPathChange(path);
    if (path === "/reschedule" && history.location.state) {
      setTestCaseID(history.location.state.rowData.testCaseID);
      setEmpID(history.location.state.empID);
      setEmail(history.location.state.email);
    }
    if (path === "/schedule") {
      onClearEmployeeForAdminDetails();
      onClearEmployeeForAdminTestCases();
      if (!currentUser.isAdmin) {
        // console.log("!currentUser.isAdmin");
        // console.log("employee: ", employee);
        setEmpID(employee.empID);
        setName(employee.empName);
        setEmail(employee.email);
        setPhoneNo(employee.phoneNum);
      }
      if (history.location.state && history.location.state.rowData) {
        // console.log("!currentUser.isAdmin");
        setEmpID(history.location.state.rowData.empID);
        setName(history.location.state.rowData.empName);
        setEmail(history.location.state.rowData.email);
        setTestCaseID(history.location.state.rowData.testCaseID);
        setPhoneNo("");
      }
    }
  }, [history]);

  useEffect(() => {
    if (currentUser.isAdmin) {
      if (employeeForAdmin && employeeForAdmin.length !== 0 && path === "/schedule" && history.location.state == undefined) {
        setEmpID(employeeForAdmin.empID);
        setName(employeeForAdmin.empName);
        setEmail(employeeForAdmin.email);
        setPhoneNo(employeeForAdmin.phoneNum);
      }
      if (!employeeForAdmin && path === "/schedule" && history.location.state === undefined) {
        setEmpID("");
        setName("");
        setEmail("");
        setPhoneNo("");
        setTestIdentifier("");
      }

      if (employeeForAdmin.length === 0 && path === "/schedule" && history.location.state === undefined) {
        setEmpID("");
        setName("");
        setEmail("");
        setPhoneNo("");
        setTestIdentifier("")
      }
    }
  }, [employeeForAdmin]);

  function validEmail(email) {
    const re = /^([a-zA-Z.+-_\d])+@\w+\..{2,3}(.{2,3})?$/;
    // console.log("valid email? ", re.test(String(email).toLowerCase()))
    return re.test(String(email).toLowerCase());
  }
  function handleEmailFocusLoss() {
    if (!currentUser.isAdmin || path !== "/schedule") {
      return;
    }
    if (validEmail(email)) {
      onSetLoading(true);
      onGetEmployeeForAdmin(email).then((res) => {
        if (res.status == 200 && res.data.length == 0) {
          swal.fire({
            title: "User does not exist in database, Go to Pending Appointments tab in admin panel and add new team member to begin process workflow ",
            timer: 15000,
          });
          setEmpID("");
          setName("");
          setEmail("");
          setPhoneNo("");
          setTestIdentifier("");
        }
        onSetLoading(false);
      });
    }
  }
  function validateDateIsBusinessDay() {
    let appointmentDateDate = new Date(dateObjToString(appointmentDate) + "T23:59:00");
    let day = appointmentDateDate.getDay();
    let isWeekend = day === 6 || day === 0;
    return !isWeekend;
  }

  function dateIsInPast() {
    const appointmentTime = slotToObject(selectedTimeRange.startTime);
    let timePeriod = slotObjectToDateString(appointmentTime);

    if (timePeriod.length === 4) {
      timePeriod = "0" + timePeriod;
    }
    let formattedTime = "T" + timePeriod;

    const formattedAppointmentDate = new Date(dateObjToString(appointmentDate) + formattedTime);

    // console.log("formattedAppointmentDate: ", formattedAppointmentDate);
    // console.log("new Date(): ", new Date());
    if (formattedAppointmentDate < new Date()) {
      return true;
    } else {
      return false;
    }
  }

  function validateReeschedulingDate() {
    let appointmentDateDate = new Date(dateObjToString(appointmentDate) + "T23:59:00");
    let reescheduleInitialDateDate = new Date(reescheduleInitialDate.substring(0, reescheduleInitialDate.indexOf("T")) + "T23:59:00");
    let isGreaterFiveBusinessDay = false;
    let nLap = 0;
    let validationDate;
    while (nLap < 5) {
      validationDate = reescheduleInitialDateDate;
      validationDate = validationDate.setDate(validationDate.getDate() + 1);
      let day = reescheduleInitialDateDate.getDay();
      let isWeekend = day === 6 || day === 0;
      if (!isWeekend) {
        nLap++;
      }

      if (reescheduleInitialDateDate.setDate(reescheduleInitialDateDate.getDate()) === appointmentDateDate.setDate(appointmentDateDate.getDate())) {
        break;
      }
    }
    if (reescheduleInitialDateDate.setDate(reescheduleInitialDateDate.getDate()) < appointmentDateDate.setDate(appointmentDateDate.getDate())) {
      isGreaterFiveBusinessDay = true;
    }
    return !isGreaterFiveBusinessDay;
  }

  function validSchedulingDate(date) {
    let appointmentDateDate = new Date(dateObjToString(date) + "T23:59:00");
    let day = appointmentDateDate.getDay();
    // Can't schedule appointment on Sunday, Monday, Friday or Saturday
    let invalidDates = [0, 1, 5, 6];
    return !invalidDates.includes(day);
  }

  function dateObjToString(dateObj) {
    let month = dateObj.month.toString().length === 1 ? "0" + dateObj.month.toString() : dateObj.month.toString();
    let day = dateObj.day.toString().length === 1 ? "0" + dateObj.day.toString() : dateObj.day.toString();
    let year = dateObj.year.toString();
    return year + "-" + month + "-" + day;
  }

  function slotToObject(time) {
    let n = time.indexOf(" ");
    let hour = time.substring(0, n);
    let period = time.substring(n + 1, time.length);
    let timeRange = {
      time: hour,
      period: period,
    };
    return timeRange;
  }

  function slotObjectToDateString(time) {
    let hour = time.time.substring(0, time.time.indexOf(":"));
    let fullHour = { ...time };
    fullHour = fullHour.time;
    if (fullHour.substring(0, 2) === "12") {
      return (fullHour += ":00");
    }
    if (time.period.toLowerCase() === "pm") {
      switch (hour) {
        case "1":
          fullHour = fullHour.replace(hour, "13");
          fullHour += ":00";
          break;
        case "2":
          fullHour = fullHour.replace(hour, "14");
          fullHour += ":00";
          break;
        case "3":
          fullHour = fullHour.replace(hour, "15");
          fullHour += ":00";
          break;
        case "4":
          fullHour = fullHour.replace(hour, "16");
          fullHour += ":00";
          break;
        case "5":
          fullHour = fullHour.replace(hour, "17");
          fullHour += ":00";
          break;
        case "10":
          fullHour = fullHour.replace(hour, "22");
          fullHour += ":00";
          break;
        default:
          break;
      }
    }

    return fullHour;
  }

  function scheduleAppointment() {
    if (!email || !validEmail(email)) {
      swal.fire({
        icon: "warning",
        title: "Please select a valid email",
        timer: 2000,
      });
      return;
    }
    if (!selectedTimeRange) {
      swal.fire({
        icon: "warning",
        title: "Please select a time for your appointment",
        timer: 2000,
      });
      return;
    }

    let selectedTime = slotToObject(selectedTimeRange.startTime);
    selectedTime = slotObjectToDateString(selectedTime);
    if (selectedTime.length < 5) {
      selectedTime = "0" + selectedTime;
    }
    let selectedDate_ = new Date(selectedTimeRange.scheduleDate + "T" + selectedTime);
    let todaysDate = new Date();

    let difference = Math.abs(selectedDate_ - todaysDate);
    let hoursDifference = difference / 1000 / 60 / 60;

    if (!currentUser.isAdmin && hoursDifference < 24) {
      swal.fire({
        icon: "warning",
        title: "Can't reschedule to a date earlier than 24 hours",
        timer: 2000,
      });
      return;
    }

    if (!validateDateIsBusinessDay()) {
      swal.fire({
        icon: "warning",
        title: "Appointments can't be on weekends",
        timer: 2000,
      });
      setAppointmentDate(defaultValue);
      return;
    }
    if (dateIsInPast()) {
      swal.fire({
        icon: "warning",
        title: "Appointments can't be in the past",
        timer: 2000,
      });
      setAppointmentDate(defaultValue);
      return;
    }
    if (!validSchedulingDate(appointmentDate) && !currentUser.isAdmin) {
      swal.fire({
        icon: "warning",
        title: "Appointments can only be scheduled on Tuesday, Wednesday and Thursday",
        timer: 3000,
      });
      setAppointmentDate(defaultValue);
      return;
    }

    if (!validateReeschedulingDate() && !currentUser.isAdmin) {
      swal.fire({
        icon: "warning",
        title: "Appointments can't be more than 5 days in the future",
        timer: 2000,
      });
      setAppointmentDate(defaultValue);
      return;
    }

    // console.log("history.location.state: ", history.location.state);
    if (history.location.state === undefined && currentUser.isAdmin) {
      // console.log("testIdentifier: ", testIdentifier);
      if (testIdentifier === "") {
        swal.fire({
          icon: "warning",
          title: "Please select a test identifier",
          timer: 2000,
        });
        return;
      }
    }

    swal
      .fire({
        title: "Are you sure you want to schedule appointment ?",
        icon: "warning",
        confirmButtonText: `Yes`,
        showCancelButton: true,
      })
      .then((result) => {
        if (result.isConfirmed === true) {
          let dt = new Date(dateObjToString(appointmentDate));
          let objStartTime = slotToObject(selectedTimeRange.startTime);
          let objEndTime = slotToObject(selectedTimeRange.endTime);
          let startTime = slotObjectToDateString(objStartTime);
          let endTime = slotObjectToDateString(objEndTime);
          dt.setMinutes(mins);
          let newTestCaseID = -1;
          // Dont Remove Commented Code Below-- It is used to restrict schedule more than one appointment for a employee
          // if (currentUser.isAdmin) {
          //   newTestCaseID = employeeForAdminTestCases[0] ? employeeForAdminTestCases[0].testCaseID : testCaseID ? testCaseID : -1;
          // } else {
          //   newTestCaseID = employeeTestCases[0] ? employeeTestCases[0].testCaseID : testCaseID ? testCaseID : -1;
          // }

          // Time selction by the user always happens in CST, irrespective of where the brower is running (US/India/Mexico) thats why the time is always sent in UTC/GMT format.
          // If user selects 9:00 AM that is a CST Time and user's timezone doesn't affects the time

          let timeDateString = timeWithDate.toString();
          let timeDateWithoutTZ = timeDateString.slice(0, 16);
          let CstTime = timeDateWithoutTZ + ":00 GMT-0500";
          let t = CstTime.replace("T", " ");
          let gmtTime = new Date(t);
          var postTime = gmtTime.toISOString();
          let postData = {
            testCaseID: newTestCaseID,
            empID: empID,
            slotID: slot,
            startTime: postTime,
            email: email,
            resourceTypeID: 1,
            endTime: postTime,
            data: { testIdentifier: testIdentifier },
          };
          onSetLoading(true);
          onBookAppointment(postData).then((status) => {
            onSetLoading(false);
            if (typeof status === "number" && status === 200) {
              swal.fire({
                icon: "success",
                title: "Appointment Scheduled",
                timer: 2000,
              });
              currentUser.isAdmin ? history.push("/admin") : history.push("/");
            } else {
              swal.fire({
                icon: "error",
                title: "Appointment couldn't be scheduled",
                timer: 2000,
              });
              // console.log(status);
            }
          });
        }
      });
  }
  const rescheduleAppointment = () => {
    if (!selectedTimeRange) {
      swal.fire({
        icon: "warning",
        title: "Please select a time for your appointment",
        timer: 2000,
      });
      return;
    }

    let selectedTime = slotToObject(selectedTimeRange.startTime);
    selectedTime = slotObjectToDateString(selectedTime);
    if (selectedTime.length < 5) {
      selectedTime = "0" + selectedTime;
    }
    let selectedDate_ = new Date(selectedTimeRange.scheduleDate + "T" + selectedTime);
    let todaysDate = new Date();

    if (dateIsInPast()) {
      swal.fire({
        icon: "warning",
        title: "Appointment's time has already passed",
        timer: 2000,
      });
      return;
    }
    if (!validSchedulingDate(appointmentDate) && !currentUser.isAdmin) {
      swal.fire({
        icon: "warning",
        title: "Appointments can only be scheduled on Tuesday, Wednesday and Thursday",
        timer: 3000,
      });
      setAppointmentDate(defaultValue);
      return;
    }

    if (!validateReeschedulingDate() && !currentUser.isAdmin) {
      swal.fire({
        icon: "warning",
        title: "Appointment's time can't be more than 5 days in the future",
        timer: 2000,
      });
      return;
    }
    let difference = Math.abs(selectedDate_ - todaysDate);
    let hoursDifference = difference / 1000 / 60 / 60;

    if (!currentUser.isAdmin && hoursDifference < 24) {
      swal.fire({
        icon: "warning",
        title: "Can't reschedule to a date earlier than 24 hours",
        timer: 2000,
      });
      return;
    }

    if (!validateDateIsBusinessDay()) {
      swal.fire({
        icon: "warning",
        title: "Appointments can't be on weekends",
        timer: 2000,
      });
      setAppointmentDate(defaultValue);
      return;
    }
    let timeDateString = timeWithDate.toString();
    let timeDateWithoutTZ = timeDateString.slice(0, 16);
    let CstTime = timeDateWithoutTZ + ":00 GMT-0500";
    let t = CstTime.replace("T", " ");
    let gmtTime = new Date(t);
    var postTime = gmtTime.toISOString();
    let postData = {
      empID: empID,
      testCaseID: testCaseID,
      slotID: slot,
      startTime: postTime,
      endTime: postTime,
      user: email,
      resourceTypeID: 1,
    };
    if (testCaseID === "" || slot === "" || timeWithDate === "") {
      swal.fire({
        icon: "warning",
        title: "Select All fields",
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }
    swal
      .fire({
        title: "Are you sure you want to reschedule the appointment?",
        icon: "warning",
        confirmButtonText: `Yes`,
        showCancelButton: true,
      })
      .then((result) => {
        if (result.isConfirmed === true) {
          onSetLoading(true);
          onRescheduling(postData, email).then((status) => {
            onSetLoading(false);
            if (typeof status === "number" && status === 200) {
              swal.fire({
                icon: "success",
                title: "Appointment Rescheduled",
                timer: 2000,
              });
              currentUser.isAdmin ? history.push("/admin") : history.push("/");
            } else {
              swal.fire({
                icon: "warning",
                title: "Appointment Not Rescheduled",
                timer: 3000,
              });
            }
          });
        }
      });
  };
  const reserveTimeSlot = (slots) => {
    if (slots.length == 0) {
      swal.fire({
        icon: "warning",
        title: "Please select atleast one time slot to reserve it",
        timer: 2000,
      });
    } else {
      let success = [];
      let failed = [];
      let length = 0;
      //CALL API REPETEADLY FOR ALL TIME SLOT BLOCKING
      slots.forEach((slot) => {
        onSetLoading(true);
        length++;
        let dateWithoutTime = new Date(slot.scheduleDate);
        let addMinsToDate = dateWithoutTime.setMinutes(dateWithoutTime.getMinutes() + slot.mins);
        let timeWithDate = new Date(addMinsToDate).toISOString();
        let timeDateString = timeWithDate.toString();
        let timeDateWithoutTZ = timeDateString.slice(0, 16);
        let CstTime = timeDateWithoutTZ + ":00 GMT-0500";
        let t = CstTime.replace("T", " ");
        let gmtTime = new Date(t);
        var postTime = gmtTime.toISOString();
        onReserveTimeSlots(slot, postTime).then((res) => {
          if (res.status === 200) {
            success.push(slot.startTime);
          } else {
            failed.push(slots.startTime);
          }
          if (length == slots.length) {
            onGetTimeSlots(dateObjToString(appointmentDate)).then((res) => {
              onSetLoading(false);
            });
          }
          if (length == slots.length && success.length > 0) {
            swal.fire({
              icon: "success",
              title: `Successfully reserved slot for ${success.join(",")} on ${slot.scheduleDate} `,
              timer: 5000,
            });
          }
          if (length == slot.length && failed.length > 0) {
            swal.fire({
              icon: "Failed",
              title: `Failed to  reserve slot for ${failed.join(",")} on ${slot.scheduleDate}`,
              timer: 10000,
            });
          }
        });
      });
    }
  };
  const unblockSlot = (bookedSlots) => {
    if (bookedSlots.length == 0) {
      swal.fire({
        icon: "warning",
        title: "Please select atleast one reserved slot to unblock it",
        timer: 2000,
      });
    } else {
      let success = [];
      let failed = [];
      let length = 0;
      //CALL API REPETEADLY FOR ALL TIME SLOT UNBLOCKING
      bookedSlots.forEach((slot) => {
        onSetLoading(true);
        length++;
        onUnblockTimeSlots(slot.calendarID).then((res) => {
          if (res.status === 200) {
            success.push(slot.slotStartTime);
          } else {
            failed.push(slot.slotStartTime);
          }
          if (length == bookedSlots.length) {
            onBlockedTimeSlots(dateObjToString(appointmentDate)).then((res) => {
              onSetLoading(false);
            });
          }
          if (length == bookedSlots.length && success.length > 0) {
            swal.fire({
              icon: "success",
              title: `Successfully unblocked slot for ${success.join(", ")} on ${slot.scheduledDate.substring(0, 10)} `,
              timer: 5000,
            });
          }
          if (length == bookedSlots.length && failed.length > 0) {
            swal.fire({
              icon: "Failed",
              title: `Failed to  unblock slot for ${failed.join(", ")} on ${slot.scheduledDate.substring(0, 10)}`,
              timer: 10000,
            });
          }
        });
      });
    }
  };

  const loadingImage = (
    <div
      style={{
        backgroundColor: "#fff",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "75vh",
        marginTop: "-40px",
        padding: "0px",
      }}
    >
      <img src={Loader} alt="loading" height="100vh" />
    </div>
  );
  // console.log("employeeForAdminTestCases", employeeForAdminTestCases);
  return (
    <>
      {loading ? (
        loadingImage
      ) : (
        <div className={classes.formContainer}>
          {history.location.state !== undefined && history.location.state.rowData !== undefined ? <RecentAppointmentInfo info={history.location.state.rowData} /> : null}
          <Grid container direction="row">
            <Grid item xs={12} sm={12} md={6} style={{ marginTop: "1%", alignContent: "center" }}>
              <Calendar appointmentDate={appointmentDate} setAppointmentDate={setAppointmentDate} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} style={{ display: "inline-block" }}>
              <div style={{ marginTop: "3%" }}>
                {path === "/unblock" ? (
                  <InputLabel id="demo-simple-select-label" className={classes.select}>
                    Reserved Time Slots ( in Central Standard Time Zone)
                  </InputLabel>
                ) : (
                  <InputLabel id="demo-simple-select-label" className={classes.select}>
                    Available Time Slots ( in Central Standard Time Zone)
                  </InputLabel>
                )}

                <TimeSelector
                  class={classes}
                  timeRanges={timeRanges}
                  appointments={appointments}
                  appointmentDate={appointmentDate}
                  bookApointment={bookAppointment}
                  slot={setSlot}
                  mins={setMins}
                  dateObjToString={dateObjToString}
                  loading={loading}
                  beforeToday={beforeToday}
                  setSelectedTimeRange={setSelectedTimeRange}
                  setTimeWithDate={setTimeWithDate}
                  selectedTimeRange={selectedTimeRange}
                  slots={slots}
                  bookedSlots={bookedSlots}
                  blockedTimeRanges={blockedTimeRanges}
                // setReservedSlots={setReservedSlots}
                />
              </div>

              <Card
                style={{
                  backgroundColor: "#fff",
                  margin: "5%",
                  boxShadow: "none",
                }}
              >
                <CardContent>
                  {path === "/schedule" ? (
                    <Typography className={classes.currentInfo}>Scheduling for</Typography>
                  ) : path === "/reschedule" ? (
                    <Typography className={classes.currentInfo}>Rescheduling to</Typography>
                  ) : path === "/block" ? (
                    <Typography className={classes.currentInfo}>Reserving time for </Typography>
                  ) : path === "/unblock" ? (
                    <Typography className={classes.currentInfo}>Unblocking time for </Typography>
                  ) : null}

                  <Typography>
                    <span className={classes.currentInfo}>Date : {new Date(dateObjToString(appointmentDate) + "T00:00:00").toDateString()}</span>
                  </Typography>
                  {/* {reservedSlots.length > 0 ? (
                    <Typography>
                      <span className={classes.currentInfo}>Time : {reservedSlots.join(",")}</span>
                    </Typography>
                  ) : null} */}

                  <Typography> {selectedTimeRange !== undefined ? <span className={classes.currentInfo}>Time : {selectedTimeRange.startTime}</span> : null}</Typography>
                </CardContent>
              </Card>

              {path === "/schedule" ? (
                <Grid item xs={12}>
                  {history.location.state !== undefined && history.location.state.rowData !== undefined ? (
                    <RecentAppointment info={history.location.state.rowData} />
                  ) : (
                    <RecentAppointment empID={employeeForAdmin === undefined || employeeForAdmin.length === 0 ? null : employeeForAdmin.empID} />
                  )}
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          {path === "/schedule" ? (
            <>
              <Grid style={{ marginLeft: "5%", marginRight: "5%", marginTop: "3%" }}>
                <Grid container direction="row">
                  <Grid item xs={6}>
                    <TextField id="standard-basic" disabled={history.location.state} className={classes.field} value={email} onBlur={() => handleEmailFocusLoss()} onChange={(e) => setEmail(e.target.value)} label="Email" />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField id="standard-basic" disabled={true} className={classes.field} value={name} onChange={(e) => setName(e.target.value)} label="Name" />
                  </Grid>
                </Grid>
                <Grid container direction="row">
                  <Grid item xs={6}>
                    <TextField id="standard-basic" type="number" disabled={true} className={classes.field} value={phoneNo} onChange={(e) => setPhoneNo(e.target.value)} label="Phone Number" />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField id="standard-basic" disabled={true} className={classes.field} value={address} onChange={(e) => setAddress(e.target.value)} label="Address" />
                  </Grid>
                </Grid>
                <Grid container direction="row">
                  <Grid item xs={6}>
                    {!history.location.state ? <FormControl disabled={!employeeForAdmin || employeeForAdmin.length === 0} className={classes.formControl}>
                      <InputLabel id="demo-simple-select-label">Test identifier</InputLabel>
                      <Select labelId="demo-simple-select-label" label="Test identifier" value={testIdentifier} onChange={(e) => setTestIdentifier(e.target.value)}>
                        <MenuItem value={"Symptomatic"}>Symptomatic</MenuItem>
                        <MenuItem value={"Car Pool Contact"}>Car Pool Contact</MenuItem>
                        <MenuItem value={"Household Contact"}>Household Contact</MenuItem>
                        <MenuItem value={"Travel"}>Travel</MenuItem>
                        <MenuItem value={"Random"}>Random</MenuItem>
                      </Select>
                    </FormControl> : <span></span>}
                  </Grid>
                  <Grid item xs={6}>
                    <span></span>
                  </Grid>
                </Grid>
              </Grid>
              {/* <div className={classes.root}>{"Add your details"}</div>
              <TextField id="standard-basic" disabled={history.location.state} className={classes.field} value={email} onBlur={() => handleEmailFocusLoss()} onChange={(e) => setEmail(e.target.value)} label="Email" />
              <TextField id="standard-basic" disabled={true} className={classes.field} value={name} onChange={(e) => setName(e.target.value)} label="Name" />
              <TextField id="standard-basic" type="number" disabled={true} className={classes.field} value={phoneNo} onChange={(e) => setPhoneNo(e.target.value)} label="Phone Number" />
              <TextField id="standard-basic" disabled={true} className={classes.field} value={address} onChange={(e) => setAddress(e.target.value)} label="Address" />
              <div> */}
              {employeeForAdminTestCases[employeeForAdminTestCases.length - 1] == undefined ||
                employeeForAdminTestCases[employeeForAdminTestCases.length - 1].startTime == null ||
                (employeeForAdminTestCases[employeeForAdminTestCases.length - 1].statusID !== 5 && employeeForAdminTestCases[employeeForAdminTestCases.length - 1].statusID !== 4) ? (
                <Button className={classes.button} onClick={() => scheduleAppointment()}>
                  <ScheduleIcon />
                  &nbsp;&nbsp; Make an Appointment
                </Button>
              ) : (
                <Button className={classes.button} onClick={() => scheduleAppointment()}>
                  <ScheduleIcon />
                  &nbsp;&nbsp; Schedule Another
                </Button>
              )}
              {/* </div> */}
            </>
          ) : path === "/reschedule" ? (
            <div>
              <Button className={classes.button} onClick={() => rescheduleAppointment()}>
                <UpdateIcon /> &nbsp;&nbsp; Update
              </Button>
            </div>
          ) : path === "/block" ? (
            <div>
              <Button className={classes.button} onClick={() => reserveTimeSlot(slots)}>
                <EventBusyIcon /> &nbsp;&nbsp; RESERVE
              </Button>
            </div>
          ) : path === "/unblock" ? (
            <div>
              <Button className={classes.button} onClick={() => unblockSlot(bookedSlots)}>
                <DateRangeIcon /> &nbsp;&nbsp; Unblock
              </Button>
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleAppointment);
