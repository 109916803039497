import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  root: { minHeight: "25vh", justifyContent: "center", margin: "1%", borderRadius: "30px" },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  action: { display: "flex", flexDirection: "row", justifyContent: "center" },
  button: {
    margin: "1%",

    fontSize: "12px",
    borderRadius: "6px",
    marginBottom: "3%",
    color: "#fff",
    backgroundColor: "#2699FB",
    "&:hover": {
      backgroundColor: "#001F53",

      color: "white",
    },
  },
  cancelReschedule: {
    backgroundColor: "#2699fb",
    color: "white",
    fontSize: "10px",
    margin: "5px",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#000",
      fontWeight: "bold",
    },
  },
});
