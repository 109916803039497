import React, { useEffect } from "react";
import Main from "./components/Main/Main";
import "./App.css";
import ScheduleAppointment from "./uiComponents/scheduleAppointment";
import LandingPage from "./uiComponents/landingScreen";
import { connect } from "react-redux";
import SuccessfullAppointment from "./uiComponents/successfullAppointment";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import AdminPanel from "./uiComponents/adminPanel";
import Reschedule from "./uiComponents/adminPanel/rescheduleAppointment.js";
import Block from "./uiComponents/adminPanel/blockTime.js";
import UnblockTime from "./uiComponents/adminPanel/unBlockTime";

// import Login from "./uiComponents/landingScreen/login";
import FlaggedEmployees from "./uiComponents/adminPanel/flaggedEmployees";
import Footer from "./uiComponents/footer/footer";
import { onGetEmployeeOktaInformation } from "./store/actions";

const mapDispatchToProps = (dispatch) => {
  return {
    onGetEmployeeOktaInformation: (oktaInformation) => dispatch(onGetEmployeeOktaInformation(oktaInformation)),
  };
};

function App(props) {
  useEffect(() => {
    props.onGetEmployeeOktaInformation(props.oktaInformation);
  }, [props]);

  return (
    <Router>
      <div className="App">
        <Main />
        <Switch>
          <>
            {/* <Route exact path="/login" component={Login} /> */}
            <Route exact path="/" component={LandingPage} />
            <Route exact path="/schedule" component={ScheduleAppointment} />
            <Route exact path="/admin" component={AdminPanel} />
            <Route exact path="/flaggedEmployees" component={FlaggedEmployees} />
            <Route exact path="/SuccessfullAppointment" component={SuccessfullAppointment} />
            <Route exact path="/reschedule" component={Reschedule} />
            <Route exact path="/block" component={Block} />
            <Route exact path="/unblock" component={UnblockTime} />

            <Route path="/callback">
              <Redirect to="/" />
            </Route>
            <Route path="*">
              <Redirect to="/" />
            </Route>
          </>
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default connect(null, mapDispatchToProps)(App);
