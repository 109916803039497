import React from "react";
import Header from "../Header/Header";
import Appointment from "../Appointment/Appointment";
import Content from "../Content/Content";
import Grid from "@material-ui/core/Grid";
import classes from "./Main.module.css";

const Main = () => {
  return (
    <>
      <Grid container direction="row" className={classes.mainContainer}>
        <Header />
        {/* <Appointment /> */}
      </Grid>
    </>
  );
};

export default Main;
