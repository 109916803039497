export const BOOK_APPOINTMENT = "BOOK_APPOINTMENT";
export const SET_EMPLOYEE = "SET_EMPLOYEE";
export const SET_EMPLOYEE_TEST_CASES = "SET_EMPLOYEE_TEST_CASES";
export const SET_EMPLOYEE_FOR_ADMIN = "SET_EMPLOYEE_FOR_ADMIN";
export const SET_EMPLOYEE_FOR_ADMIN_TEST_CASES = "SET_EMPLOYEE_FOR_ADMIN_TEST_CASES";
export const SET_AVAILABLE_TIME_RANGES = "SET_AVAILABLE_TIME_RANGES";
export const SET_LOADING = "SET_LOADING";
export const SET_APPOINTMENTS = "SET_APPOINTMENTS";
export const SET_FOLLOWUP_NOTES = "SET_FOLLOWUP_NOTES";
export const SET_NON_COMPLIANT_EMPLOYEES = "SET_NON_COMPLIANT_EMPLOYEES";
export const SET_FOLLOWUPS = "SET_FOLLOWUPS";
export const SET_PATH = "SET_PATH";
export const SET_EMPLOYEE_OKTA_INFORMATION = "SET_EMPLOYEE_OKTA_INFORMATION";
export const SET_ALL_EMPLOYEES = "SET_ALL_EMPLOYEES";
export const RESERVED_SLOTS = "RESERVED_SLOTS";
export const SET_BLOCKED_TIME = "BLOCKED_TIME";
