import * as actionTypes from "./actionTypes";
import serverAxios from "../../services/axios-instance";
import config from "../../config";

const sort_by_key = (array, key, asc) => {
  return array.sort((a, b) => {
    let x = a[key];
    let y = b[key];
    return asc ? (x < y ? -1 : x > y ? 1 : 0) : x < y ? 1 : x > y ? -1 : 0;
  });
};

const onSetAppointments = (appointments) => {
  return {
    type: actionTypes.SET_APPOINTMENTS,
    appointments: appointments,
  };
};

const onSetFollowUpEmployees = (followup) => {
  return {
    type: actionTypes.SET_FOLLOWUPS,
    payload: followup,
  };
};

const onSetNonCompliantEmployees = (nonCompliantEmployees) => {
  return {
    type: actionTypes.SET_NON_COMPLIANT_EMPLOYEES,
    nonCompliantEmployees: nonCompliantEmployees,
  };
};

const onSetAllEmployees = (employees) => {
  return {
    type: actionTypes.SET_ALL_EMPLOYEES,
    allEmployees: employees,
  };
};

export const getAllEmployees = () => {
  return (dispatch, getState) => {
    let oktaToken = JSON.parse(window.sessionStorage.getItem("okta-token-storage")).accessToken;
    serverAxios.defaults.headers.common.authorization = `Bearer ${oktaToken.accessToken}`;
    return new Promise((resolve, rej) => {
      serverAxios
        .get("/admin/allEmployees")
        .then((result) => {
          dispatch(onSetAllEmployees(result.data));
          resolve();
        })
        .catch((err) => {
          resolve(err);
        });
    });
  };
};

const hasConsentForm = (testCaseID) => {
  let oktaToken = JSON.parse(window.sessionStorage.getItem("okta-token-storage")).accessToken;
  serverAxios.defaults.headers.common.authorization = `Bearer ${oktaToken.accessToken}`;
  return new Promise((resolve, rej) => {
    serverAxios
      .get(`/appointment/testCasesDetails/${testCaseID}`)
      .then((result) => {
        let testCaseData = result.data;
        console.log("testCaseData: ", testCaseData);
        let hasConsentForm = false;
        if (testCaseData.bookingData) {
          hasConsentForm = JSON.parse(testCaseData.bookingData).consentFormID ? true : false;
        }
        resolve(hasConsentForm);
      })
      .catch((err) => {
        resolve(err);
      });
  });
};

export const sendConsentForm = (email, testCaseID) => {
  let oktaToken = JSON.parse(window.sessionStorage.getItem("okta-token-storage")).accessToken;
  serverAxios.defaults.headers.common.authorization = `Bearer ${oktaToken.accessToken}`;
  return new Promise((resolve, rej) => {
    let body = {
      agreementPayload: {
        fileInfos: [
          {
            libraryDocumentId: "CBJCHBCAABAAWTV5DuehCxaeIkX7QSwpFdjqNm9UHbVU",
          },
        ],
        name: `Covid consent form ${config.environment.environment}`,
        participantSetsInfo: [
          {
            memberInfos: [
              {
                email: email,
              },
            ],
            order: 1,
            role: "SIGNER",
          },
        ],
        signatureType: "ESIGN",
        state: "IN_PROCESS",
        type: "AGREEMENT",
      },
      bookingData: {
        id: testCaseID,
      },
    };
    serverAxios
      .post("/sendConsentForm", body)
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        resolve(err);
      });
  });
};

export const onUpdatePath = (path) => {
  return {
    type: actionTypes.SET_PATH,
    path: path,
  };
};

export const getAllAppointments = (nextMonth, offset) => {
  console.log("offset: ", offset);
  return (dispatch, getState) => {
    let today = new Date();
    let params = {
      params: {
        resourceTypeID: 1,
        searchDate: nextMonth ? nextMonth : today,
        offset: offset ? offset : 0,
      },
    };
    let appointments = [];
    console.log("params: ", params);
    let oktaToken = JSON.parse(window.sessionStorage.getItem("okta-token-storage")).accessToken;
    serverAxios.defaults.headers.common.authorization = `Bearer ${oktaToken.accessToken}`;
    return new Promise((resolve, rej) => {
      serverAxios
        .get("/appointments", params)
        .then((result) => {
          let data = result.data;
          if (offset) {
            appointments = [...getState().appointment.appointments];
            data.push(...appointments);
            appointments = sort_by_key(appointments, "testCaseID", false);
            // console.log("sorted appointments: ", appointments);
          }
          data.forEach(appointment => {
            if (appointment.bookingData) {
              appointment.bookingData = JSON.parse(appointment.bookingData)
            }
          })
          dispatch(onSetAppointments(data));
          resolve();
        })
        .catch((err) => {
          resolve(err);
        });
    });
  };
};

export const cancelAppointment = (cancelData) => {
  return (dispatch, getState) => {
    let oktaToken = JSON.parse(window.sessionStorage.getItem("okta-token-storage")).accessToken;
    serverAxios.defaults.headers.common.authorization = `Bearer ${oktaToken.accessToken}`;
    return new Promise((resolve, rej) => {
      serverAxios
        .post("/appointment/cancel", cancelData)
        .then((result) => {
          if (result.status === 200) {
            dispatch(getAllAppointments);
            resolve(result.status);
          } else {
            resolve(result.status);
          }
        })
        .catch((err) => {
          resolve(err);
        });
    });
  };
};

export const getAppointmentsByStatus = (filterObj) => {
  // filterObj = [{ status: number or string, type: 1 or 2, offset: number}]
  // Type 1 = Parameter should be STATE_FILTER
  // Type 2 = Parameter should be STATUS_ID

  return (dispatch, getState) => {
    let oktaToken = JSON.parse(window.sessionStorage.getItem("okta-token-storage")).accessToken;
    serverAxios.defaults.headers.common.authorization = `Bearer ${oktaToken.accessToken}`;
    return new Promise((resolve, rej) => {
      let params;
      let results = [];
      filterObj.forEach((filter, index) => {
        params =
          filter.type === 1 ? { params: { resourceTypeID: 1, statusType: filter.status, offset: filter.offset ? filter.offset : 0 } } : { params: { resourceTypeID: 1, statusID: filter.status, offset: filter.offset ? filter.offset : 0 } };
        console.log("params: ", params);
        serverAxios
          .get("/appointments", filter.status !== "" ? params : { params: { resourceTypeID: 1, offset: filter.offset ? filter.offset : 0 } })
          .then((result) => {
            let data = result.data;
            results.push(...data);
            if (index === filterObj.length - 1) {
              if (filter.offset) {
                results = [...getState().appointment.appointments];
                data.push(...results);
              }
              results.forEach(appointment => {
                if (appointment.bookingData) {
                  appointment.bookingData = JSON.parse(appointment.bookingData)
                }
              })
              dispatch(onSetAppointments(results));
              resolve();
            }
          })
          .catch((err) => {
            resolve(err);
          });
      });
    });
  };
};

export const rescheduleAppointment = (data, email) => {
  return (dispatch) => {
    let oktaToken = JSON.parse(window.sessionStorage.getItem("okta-token-storage")).accessToken;
    serverAxios.defaults.headers.common.authorization = `Bearer ${oktaToken.accessToken}`;
    return new Promise((resolve, rej) => {
      serverAxios
        .post("/appointment/reschedule", data)
        .then((result) => {
          if (result.status === 200) {
            dispatch(getAllAppointments);
            hasConsentForm(data.testCaseID).then((consentFormExists) => {
              console.log("consentFormExists: ", consentFormExists);
              if (!consentFormExists) {
                sendConsentForm(email, data.testCaseID).then((consentForm) => {
                  // let consentFormID = consentForm.data.id;
                  // console.log("consentForm: ", consentForm)
                  resolve(result.status);
                });
              } else {
                resolve(result.status);
              }
            });
            resolve(result.status);
          } else {
            resolve(result.status);
          }
        })
        .catch((err) => {
          resolve(err);
        });
    });
  };
};

export const getNonCompliantEmployees = () => {
  return (dispatch) => {
    let oktaToken = JSON.parse(window.sessionStorage.getItem("okta-token-storage")).accessToken;
    serverAxios.defaults.headers.common.authorization = `Bearer ${oktaToken.accessToken}`;
    return new Promise((resolve, rej) => {
      serverAxios
        .get("/admin/employee-compliance")
        .then((result) => {
          dispatch(onSetNonCompliantEmployees(result.data));
          resolve();
        })
        .catch((err) => {
          resolve(err);
        });
    });
  };
};

export const flagToggleNonCompliantEmployee = (empID, nonCompliant) => {
  return (dispatch) => {
    let body = {
      empID: empID,
      status: nonCompliant,
    };
    let oktaToken = JSON.parse(window.sessionStorage.getItem("okta-token-storage")).accessToken;
    serverAxios.defaults.headers.common.authorization = `Bearer ${oktaToken.accessToken}`;
    return new Promise((resolve, rej) => {
      serverAxios
        .post("/admin/employee-compliance", body)
        .then((result) => {
          resolve(result.status);
        })
        .catch((err) => {
          resolve(err);
        });
    });
  };
};

export const getFollowUpEmployees = (offset) => {
  return (dispatch, getState) => {
    let params = {
      params: {
        resourceTypeID: 2,
        offset: offset ? offset : 0,
      },
    };
    let oktaToken = JSON.parse(window.sessionStorage.getItem("okta-token-storage")).accessToken;
    serverAxios.defaults.headers.common.authorization = `Bearer ${oktaToken.accessToken}`;
    return new Promise((resolve, rej) => {
      serverAxios
        .get("/appointments", params)
        .then((result) => {
          let data = result.data;
          let followUps = [];
          if (offset) {
            followUps = [...getState().admin.followUp];
            console.log("followUps: ", followUps);
            data.push(...followUps);
          }
          dispatch(onSetFollowUpEmployees(data));
          resolve();
        })
        .catch((err) => {
          resolve(err);
        });
    });
  };
};

export const postFollowUp = (data) => {
  return (dispatch, getState) => {
    let employee = getState().employee.employee;
    let postData = {
      calendarID: data.calendarID ? data.calendarID : null,
      empID: employee.empID,
      slotID: -1,
      startTime: data.startTime,
      endTime: data.startTime,
      resourceTypeID: 2,
      bookingnotes: {
        title: data.title,
        content: data.content,
        employeeName: data.employeeName,
        priorityLevel: data.priorityLevel,
      },
    };
    return new Promise((resolve, rej) => {
      let oktaToken = JSON.parse(window.sessionStorage.getItem("okta-token-storage")).accessToken;
      serverAxios.defaults.headers.common.authorization = `Bearer ${oktaToken.accessToken}`;
      serverAxios
        .post("/admin/appointment/followup", postData)
        .then((result) => {
          if (result.status === 200) {
            resolve(result.status);
          } else {
            alert("Failed");
            resolve(result.status);
          }
        })
        .catch((err) => {
          resolve(err);
        });
    });
  };
};

export const cancelFollowUp = (cancelData) => {
  return (dispatch, getState) => {
    let employee = getState().employee.employee;
    let oktaToken = JSON.parse(window.sessionStorage.getItem("okta-token-storage")).accessToken;
    serverAxios.defaults.headers.common.authorization = `Bearer ${oktaToken.accessToken}`;
    return new Promise((resolve, rej) => {
      serverAxios
        .post("/admin/appointment/followup/cancel", cancelData)
        .then((result) => {
          if (result.status === 200) {
            dispatch(getFollowUpEmployees);
            resolve(result.status);
          } else {
            resolve(result.status);
          }
        })
        .catch((err) => {
          resolve(err);
        });
    });
  };
};

export const getConsentFormStatus = () => {
  return (dispatch, getState) => {
    let appointments = [...getState().appointment.appointments];
    let oktaToken = JSON.parse(window.sessionStorage.getItem("okta-token-storage")).accessToken;
    serverAxios.defaults.headers.common.authorization = `Bearer ${oktaToken.accessToken}`;
    return new Promise((resolve, rej) => {
      let consentFormIDs = [];
      let appointmentData = [];
      appointments.forEach((appointment) => {
        appointment.consentFormStatus = "Not sent";
        if (appointment.bookingData) {
          if (!appointment.bookingData.consentFormID) {
            appointment.consentFormStatus = "Not sent";
          }
          if (appointment.bookingData.consentFormID && appointment.bookingData.isSigned !== "true") {
            appointmentData.push({ testCaseID: appointment.testCaseID, consentFormID: appointment.bookingData.consentFormID });
            consentFormIDs.push(appointment.bookingData.consentFormID);
            appointment.consentFormStatus = "Not signed";
          }
          if (appointment.bookingData.isSigned && appointment.bookingData.isSigned === "true") {
            appointment.consentFormStatus = "Signed";
          }
        }
      });
      let body = {
        appointmentData: appointmentData,
      };

      serverAxios.post(`/getAgreementsByID`, body).then((result) => {
        result.data.forEach((consentForm) => {
          for (let i = 0; i < appointments.length; i++) {
            if (appointments[i].bookingData) {
              if (appointments[i].bookingData.consentFormID === consentForm.id) {
                appointments[i].consentFormStatus = consentForm.status === "SIGNED" ? "Signed" : "Not signed";
                break;
              }
            }
          }
        });
        appointments = sort_by_key(appointments, "testCaseID", false);
        // console.log("sorted appointments: ", appointments);
        dispatch(onSetAppointments(appointments));
        resolve(result);
      });
    });
  };
};

export const addMemberToWorkflowProcess = (data) => {
  return (dispatch, getState) => {
    let employee = getState().employee.employee;
    let newMemberInfo = {
      empEmail: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      mobileNumber: data.phoneNumber,
      employeeNum: data.employeeNumber,
    };
    let oktaToken = JSON.parse(window.sessionStorage.getItem("okta-token-storage")).accessToken;
    serverAxios.defaults.headers.common.authorization = `Bearer ${oktaToken.accessToken}`;
    return new Promise((resolve, rej) => {
      serverAxios
        .post("/employee/new", newMemberInfo)
        .then((result) => {
          if (result.status === 200) {
            resolve(result);
          } else {
            resolve(result.status);
          }
        })
        .catch((err) => {
          resolve(err);
        });
    });
  };
};

export const addToWorkFlow = (eId) => {
  return (dispatch, getState) => {
    let employee = getState().employee.employee;
    let info = {
      empID: eId,
    };
    let oktaToken = JSON.parse(window.sessionStorage.getItem("okta-token-storage")).accessToken;
    serverAxios.defaults.headers.common.authorization = `Bearer ${oktaToken.accessToken}`;
    return new Promise((resolve, rej) => {
      serverAxios
        .post("/workflow/add-employee", info)
        .then((result) => {
          if (result.status === 200) {
            resolve(result);
            getAllAppointments();
          } else {
            resolve(result.status);
          }
        })
        .catch((err) => {
          resolve(err);
        });
    });
  };
};

export const moveToTestingInProgress = (testId) => {
  return (dispatch, getState) => {
    let employee = getState().employee.employee;
    let body = {
      testCaseID: testId,
      statusID: 5,
    };
    let oktaToken = JSON.parse(window.sessionStorage.getItem("okta-token-storage")).accessToken;
    serverAxios.defaults.headers.common.authorization = `Bearer ${oktaToken.accessToken}`;
    return new Promise((resolve, rej) => {
      serverAxios
        .post("/workflow/status", body)
        .then((result) => {
          if (result.status === 200) {
            resolve(result);
          } else {
            resolve(result.status);
          }
        })
        .catch((err) => {
          resolve(err);
        });
    });
  };
};

export const testingCompleted = (testId) => {
  return (dispatch, getState) => {
    let employee = getState().employee.employee;
    let body = {
      testCaseID: testId,
      statusID: 6,
    };
    let oktaToken = JSON.parse(window.sessionStorage.getItem("okta-token-storage")).accessToken;
    serverAxios.defaults.headers.common.authorization = `Bearer ${oktaToken.accessToken}`;
    return new Promise((resolve, rej) => {
      serverAxios
        .post("/workflow/status", body)
        .then((result) => {
          if (result.status === 200) {
            resolve(result);
          } else {
            resolve(result.status);
          }
        })
        .catch((err) => {
          resolve(err);
        });
    });
  };
};

export const reserveTimeSlots = (slot, postTime) => {
  return (dispatch, getState) => {
    let employee = getState().employee.employee;
    let body = {
      empID: employee.empID,
      resourceTypeID: 1,
      slotID: slot.slotID,
      startTime: postTime,
      endTime: postTime,
    };
    let oktaToken = JSON.parse(window.sessionStorage.getItem("okta-token-storage")).accessToken;
    serverAxios.defaults.headers.common.authorization = `Bearer ${oktaToken.accessToken}`;
    return new Promise((resolve, rej) => {
      serverAxios
        .post("/admin/appointment/reserve-time", body)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          resolve(err);
        });
    });
  };
};

export const reservedSlots = (slots) => {
  return {
    type: actionTypes.RESERVED_SLOTS,
    data: slots,
  };
};

export const unblockTimeSlots = (calendarID) => {
  console.log("calendar id", calendarID);
  return (dispatch, getState) => {
    let employee = getState().employee.employee;
    let body = {
      calendarID: calendarID,
    };

    let oktaToken = JSON.parse(window.sessionStorage.getItem("okta-token-storage")).accessToken;
    serverAxios.defaults.headers.common.authorization = `Bearer ${oktaToken.accessToken}`;
    return new Promise((resolve, rej) => {
      serverAxios
        .post("/admin/appointment/reserved-time/cancel", body)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          resolve(err);
        });
    });
  };
};
