import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import classes from "./Appointment.module.css";
import moment from "moment";
import { connect } from "react-redux";
import { getEmployeeTestCases, cancelAppointment, getEmployeeForAdminTestCases, onClearEmployeeForAdminTestCases } from "../../store/actions/index";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import CancelIcon from "@material-ui/icons/Cancel";
import swal from "sweetalert2";

const mapDispatchToProps = (dispatch) => {
  return {
    getEmployeeTestCases: (value) => dispatch(getEmployeeTestCases(value)),
    onGetEmployeeForAdminTestCases: (id) => dispatch(getEmployeeForAdminTestCases(id)),
    onClearEmployeeForAdminTestCases: () => dispatch(onClearEmployeeForAdminTestCases()),
    cancelAppointment: (value) => dispatch(cancelAppointment(value)),
  };
};

const mapStateToProps = (state) => {
  return {
    employeeTestCases: state.employee.employeeTestCases,
    employee: state.employee.employee,
    employeeForAdminTestCases: state.employee.employeeForAdminTestCases,
    employeeForAdmin: state.employee.employeeForAdmin,
    employeeOktaInformation: state.employee.employeeOktaInformation,
    currentUser: state.employee.currentUser,
  };
};
const RecentAppointment = (props) => {
  const { employeeTestCases, getEmployeeTestCases, cancelAppointment, employee, onGetEmployeeForAdminTestCases, employeeForAdmin, employeeForAdminTestCases, employeeOktaInformation, currentUser, onClearEmployeeForAdminTestCases } = props;
  // useEffect(() => {
  //   console.log("props.empID", props.empID);
  //   if (!currentUser.isAdmin) {
  //     getEmployeeTestCases();
  //   }
  // }, [props]);
  let latestAppointment = currentUser.isAdmin ? employeeForAdminTestCases[employeeForAdminTestCases.length - 1] : employeeTestCases[employeeTestCases.length - 1];
  useEffect(() => {
    if (employeeForAdmin.empID) {
      if (props.info) {
        onGetEmployeeForAdminTestCases(props.info.empID);
      }
      if (props.empID) {
        onGetEmployeeForAdminTestCases(props.empID);
      }
    } else {
      onClearEmployeeForAdminTestCases();
    }

    // onGetEmployeeForAdminTestCases(employeeForAdmin.empID);
  }, [employeeForAdmin]);

  const status = () => {
    switch (latestAppointment.statusID) {
      case 1:
        return "Employee identified for testing";
      case 2:
        return "Employee notified, employee action pending";
      case 3:
        return "Multiple reminder sent, need manual action";
      case 4:
        return "Employee booking confirmed";
      case 5:
        return "Testing in progress";
      case 6:
        return "Testing complete, result uploaded";
      case 7:
        return "Appointment cancelled";
      default:
        break;
    }
  };
  let path = window.location.pathname;
  const history = useHistory();

  const handleCancelAppointment = () => {
    swal
      .fire({
        title: "Are you sure you want to cancel this appointment?",
        icon: "warning",
        confirmButtonText: `Yes`,
        showCancelButton: true,
      })
      .then((result) => {
        if (result.isConfirmed === true) {
          let data = {
            testCaseID: latestAppointment.testCaseID,
            resourceTypeID: 1,
          };
          if (currentUser.isAdmin) {
            data.empID = employeeForAdmin.empID;
          }
          cancelAppointment(data).then((status) => {
            if (status === 200) {
              swal.fire({
                icon: "success",
                title: "Appointment Cancelled",
                timer: 2000,
              });
              if (employeeOktaInformation && !currentUser.isAdmin) {
                getEmployeeTestCases();
              }
              currentUser.isAdmin ? history.push("/admin") : history.push("/");
            } else {
              swal.fire({
                icon: "warning",
                title: "Appointment Not Cancelled",
                timer: 3000,
              });
            }
          });
        }
      });
  };

  let testCases = currentUser.isAdmin ? employeeForAdminTestCases : employeeTestCases;
  // console.log("testCases: ", testCases)
  const convertGMTToCSTTime = (gmtTime) => {
    var m = moment.utc(gmtTime);
    m.tz("America/Chicago");
    return m.format("YYYY-MM-DD  hh:mm A");
  };

  return (
    <>
      <Grid item xs={12} sm={12} md={12} className={classes.section}>
        <div className={classes.appointment}>
          {testCases.length === 0 || latestAppointment.statusID === 7 ? (
            <span style={{ display: "flex", justifyContent: "center", width: "30vw" }}> No Current Appointment</span>
          ) : (
            <>
              <Grid container direction="row" className={classes.gridRow}>
                <Grid item xs={12}>
                  <span className={classes.details}>Current Appointment Details</span>
                </Grid>
              </Grid>
              {latestAppointment.slotID !== null ? (
                <Grid item xs={12}>
                  <span className={classes.name}>Slot ID - {latestAppointment.slotID}</span>
                </Grid>
              ) : null}

              <Grid item xs={12}>
                {/* <span className={classes.name}>Test Case ID - {latestAppointment.testCaseID}</span> */}
              </Grid>
              <Grid item xs={12}>
                {latestAppointment.statusID !== 1 && latestAppointment.statusID !== 6 ? <span className={classes.name}>Start Time - {convertGMTToCSTTime(latestAppointment.startTime)}</span> : null}
              </Grid>
              <Grid container direction="row" className={classes.gridRow}></Grid>
              <Grid container direction="row">
                <Grid item xs={12}>
                  <span className={classes.name}>Status - {status()}</span>
                </Grid>
              </Grid>
              {latestAppointment.startTime !== null && latestAppointment.statusID !== 2 && latestAppointment.statusID !== 3 && latestAppointment.statusID !== 5 && latestAppointment.statusID !== 6 ? (
                <Grid container direction="row">
                  <Button onClick={() => handleCancelAppointment()} style={{ backgroundColor: "#2699fb", color: "#fff", fontSize: "10px", margin: "10px" }}>
                    <CancelIcon onClick={() => handleCancelAppointment()} style={{ fontSize: "20px" }} />
                    Cancel
                  </Button>
                  <Button
                    onClick={() =>
                      history.push("/reschedule", {
                        rowData: latestAppointment,
                        empID: currentUser.isAdmin ? employeeForAdmin.empID : employee.empID,
                        email: employee.email,
                      })
                    }
                    style={{ backgroundColor: "#2699fb", color: "#fff", fontSize: "10px", margin: "10px" }}
                  >
                    <RotateLeftIcon
                      onClick={() =>
                        history.push("/reschedule", {
                          rowData: latestAppointment,
                          empID: currentUser.isAdmin ? employeeForAdmin.empID : employee.empID,
                          email: employee.email,
                        })
                      }
                      style={{ fontSize: "20px" }}
                    />
                    Reschedule
                  </Button>
                </Grid>
              ) : null}
            </>
          )}
        </div>
      </Grid>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RecentAppointment);
