// Override http config
module.exports.api = {
    url: 'http://localhost:4000/',
};

module.exports.okta = {
    clientId: '0oaxzdqlq9sB7yzw60h7',
    url: 'https://tyson-dev.oktapreview.com/',
    issuer: 'https://tyson-dev.oktapreview.com/oauth2/default',
    redirectUri: 'http://localhost:3000/',
};

module.exports.environment = {
    environment: 'dev'
}


module.exports.version = { number: '1.0' }
