import React from "react";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import { useStyles } from "./styling";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return {
    employee: state.employee.employee,
  };
};

const SuccessfullAppointment = (props) => {
  const { employee } = props;
  const classes = useStyles();
  const history = useHistory();
  return (
    <>
      <Card className={classes.root} variant="outlined">
        <CardContent>
          <Typography variant="h5" component="h2">
            Your appointment has been created successfully!
          </Typography>
        </CardContent>
        <CardActions className={classes.action}>
          <Button
            size="small"
            className={classes.button}
            onClick={() => {
              employee.empID === 1 ? history.push("/admin") : history.push("/");
            }}
          >
            Back
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

export default connect(mapStateToProps, null)(SuccessfullAppointment);
