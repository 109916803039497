import { fade, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  search: {
    position: "relative",
    marginTop: "20px",
    // borderRadius: theme.shape.borderRadius,
    borderRadius: "10px",
    backgroundColor: "white",
    maxHeight: "30px",

    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "130%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "black",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
  select: {
    minWidth: "100%",
    paddingTop: 0,
  },
  tableContainer: {
    marginTop: "15px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid red !important",
    borderRadius: "5px",
  },
  followUpContainer: {
    // marginTop: "12%",
    // marginLeft: "15%",
    width: "80%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  button: {
    margin: "1%",

    fontSize: "12px",
    borderRadius: "10px",
    marginBottom: "3%",
    backgroundColor: "#2699FB",
    color: "white",

    "&:hover": {
      backgroundColor: "#001F53",
      color: "#fff",
    },
  },
  formTextField: {
    width: "100%",
    backgroundColor: "#FFFFFF",
  },
  formDateField: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    border: "1px solid #CCCCCC",
    borderRadius: "4px",
  },
  formRow: {
    marginTop: "1.5%",
  },
  formButton: {
    fontSize: "12px",
    borderRadius: "10px",
    marginBottom: "3%",
    backgroundColor: "#2699FB",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#001F53",
      color: "white",
    },
  },
  cancelButton: {
    backgroundColor: "red",
    color: "white",
    fontSize: "12px",
    borderRadius: "10px",
    marginBottom: "3%",
    marginLeft: "3%",
    "&:hover": {
      backgroundColor: "#d40000",
      color: "white",
    },
  },

  tabButton: {
    marginTop: "2px",
    width: "100%",
    color: "black",
    backgroundColor: "#EDEDEE",
    // fontWeight: "bold",
    "&:hover": {
      backgroundColor: "#2699FB",
      color: "white",
    },
  },
  tabButtonActive: {
    marginTop: "2px",
    color: "#2699FB",
    backgroundColor: "white",
    width: "100%",
    borderBottom: "3px solid",
    fontWeight: "bold",
    "&:hover": {
      color: "black",
      backgroundColor: "white",
    },
  },
  searchContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    // margin: "2%",
  },
  table: {
    minWidth: "auto",
    height: "auto",
  },
  header: {
    backgroundColor: "#fff",
    color: "black",
    fontWeight: "bold",
    fontSize: "15px",
  },
  edit: {
    fontSize: "10px",
    "&:hover": {
      backgroundColor: "#001F53",
      color: "white",
    },
  },
  row: {
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#cddffa",
      color: "white",
    },
  },
  head: {
    backgroundColor: "white",
    color: "white",
  },
  addButton: {
    marginRight: "5px",
    marginLeft: "5px",
    // marginTop: "15px",
    backgroundColor: "#2699FB",
    color: "#fff",
    fontSize: "12px",
    borderRadius: "10px",
    // paddingBottom: "3%",
    "&:hover": {
      backgroundColor: "#001F53",
      color: "white",
    },
  },

  addMember: {
    fontSize: "12px",
    borderRadius: "10px",
    backgroundColor: "#2699FB",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#001F53",
      color: "white",
    },
  },
  memberButtonDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  followUp: {
    fontSize: "12px",
    borderRadius: "10px",
    backgroundColor: "#2699FB",
    color: "#fff",
    "&:hover": {
      color: "white",
      backgroundColor: "#001F53",
    },
  },
  memberButton: {
    borderRadius: "10px",
    margin: "2%",
    backgroundColor: "#2699FB",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#001F53",
      color: "white",
    },
  },
  newMemberField: {
    margin: "5px 0px",
  },
  formControl: {
    // margin: theme.spacing(1),
    marginTop: "10px",
    minWidth: "100%",
  },
  buttonPadding: {
    paddingRight: "10px",
  },
}));
