import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import moment from "moment";
import { connect } from "react-redux";
import swal from "sweetalert2";
import "./custom.css";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import { getAllAppointments, getConsentFormStatus } from "../../store/actions/index";
const mapDispatchToProps = (dispatch) => {
  return {
    getAllAppointments: (value) => dispatch(getAllAppointments(value)),
    onGetConsentFormStatus: () => dispatch(getConsentFormStatus()),
  };
};

const mapStateToProps = (state) => {
  return {
    appointments: state.appointment.appointments,
    callFromCalendar: state.appointment.callApiFromCalendar,
    followUpNotes: state.admin.followUpNotes,
    followUp: state.admin.followUp,
  };
};

const HistoryCalendar = (props) => {
  const { appointments, tabPosition, followUpNotes, followUp, getAllAppointments, callFromCalendar, onGetConsentFormStatus } = props;
  // console.log("appointments: ", appointments)
  const convertGMTToCSTDate = (gmtTime) => {
    var timelagging = 5; // 5 or 6
    var utc = new Date(gmtTime);
    var cdt = new Date(utc.getTime() - 1 * 60 * 60 * 1000 * timelagging);
    return cdt;
  };
  let event = [];
  if (tabPosition === 1) {
    for (var i = 0; i < appointments.length; i++) {
      if (appointments[i].statusID !== 2 && appointments[i].statusID !== 3) {
        let obj = {};
        // console.log("appointments[i].bookingData: ", appointments[i].bookingData)
        // console.log("appointments[i].bookingData.testIdentifier", appointments[i].bookingData ? appointments[i].bookingData.testIdentifier : null)
        let testIdentifier = appointments[i].bookingData && appointments[i].bookingData.testIdentifier ? appointments[i].bookingData.testIdentifier : "";
        obj.testIdentifier = testIdentifier;
        obj.title = appointments[i].empName + " " + testIdentifier;
        obj.modalTitle = appointments[i].empName;
        obj.start = convertGMTToCSTDate(appointments[i].startTime);
        obj.end = convertGMTToCSTDate(appointments[i].startTime).setMinutes(convertGMTToCSTDate(appointments[i].startTime).getMinutes() + 30);
        event.push(obj);
      }
    }
  }
  if (tabPosition === 2) {
    for (var i = 0; i < appointments.length; i++) {
      if (appointments[i].statusID == 2 || appointments[i].statusID == 3) {
        let obj = {};
        obj.title = appointments[i].empName;
        obj.start = appointments[i].startTime;
        event.push(obj);
      }
    }
  }

  function empName(event) {
    if (event.bookingNotes !== null && event.bookingNotes.employeeName) {
      return "<strong>Employee Name :  </strong>  " + event.bookingNotes.employeeName + "<br/>";
    } else return "";
  }
  function content(event) {
    if (event.bookingNotes !== null && event.bookingNotes.content) {
      return "<strong>Content :  </strong>  " + event.bookingNotes.content + "<br/>";
    } else return "";
  }
  function title(event) {
    if (event.bookingNotes !== null && event.bookingNotes.title) {
      return "<strong>Title :  </strong>  " + event.bookingNotes.title + "<br/>";
    } else return "";
  }
  function calendarView() {
    if (tabPosition !== 3) {
      return "dayGridMonth";
    } else return "timeGridDay";
  }

  return (
    <div className="App">
      <FullCalendar
        timeZone="UTC"
        showNonCurrentDates={false}
        fixedWeekCount={false}
        defaultView="dayGridMonth"
        header={{
          left: "prev,next",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        plugins={[dayGridPlugin, timeGridPlugin]}
        events={event}
        datesRender={function (view, element) {
          let calDate = new Date(view.view.activeStart.setMonth(view.view.activeStart.getMonth()));
          if (callFromCalendar) {
            getAllAppointments(calDate).then(onGetConsentFormStatus().then(() => {}));
          }

          // console.log($("#calendar").fullCalendar("getDate"));
        }}
        eventClick={function (event) {
          swal.fire({
            title: event.event.extendedProps.modalTitle,
            html: event.event.extendedProps.testIdentifier
              ? "<strong>Test identifer:  </strong>  " +
                event.event.extendedProps.testIdentifier +
                "<br/>" +
                "<strong>Time:  </strong>  " +
                moment.utc(event.event.start).format("hh:mm A") +
                "<br/>" +
                "<strong> Event Date:  </strong>  " +
                event.event.start.getDate() +
                "/" +
                (event.event.start.getMonth() + 1) +
                "/" +
                event.event.start.getFullYear() +
                " " +
                event.event.start.toLocaleString("en-us", { weekday: "long" }) +
                "<br/>"
              : "<strong>Time:  </strong>  " +
                moment.utc(event.event.start).format("hh:mm A") +
                "<br/>" +
                "<strong> Event Date:  </strong>  " +
                event.event.start.getDate() +
                "/" +
                (event.event.start.getMonth() + 1) +
                "/" +
                event.event.start.getFullYear() +
                " " +
                event.event.start.toLocaleString("en-us", { weekday: "long" }) +
                "<br/>",
          });
        }}
      />
    </div>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(HistoryCalendar);
