import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import classes from "./Header.module.css";
import { connect } from "react-redux";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useHistory } from "react-router-dom";
import { loginWithRedirect, authClient } from "../../services/auth-instance";

const mapStateToProps = (state) => {
  return {
    path: state.admin.path,
    userDetails: state.employee.employeeOktaInformation,
    currentUser: state.employee.currentUser,
  };
};

const Header = (props) => {
  const { path, userDetails, currentUser } = props;
  const history = useHistory();
  const [pageTitle, setPageTitle] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const Logout = () => {
    authClient.tokenManager.clear();
    window.sessionStorage.setItem("accessToken", null);
    authClient.token.getUserInfo(JSON.parse(window.sessionStorage.getItem("okta-token-storage")).accessToken).then((user) => {
      window.sessionStorage.setItem("user", null);
      window.sessionStorage.getItem("okta-token-storage", null);
      loginWithRedirect();
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    switch (path) {
      case "/":
        setPageTitle("");
        break;
      case "/schedule":
        setPageTitle("Schedule appointment");
        break;
      case "/reschedule":
        setPageTitle("Reschedule appointment");
        break;
      case "/block":
        setPageTitle("Block Time");
        break;
      case "/unblock":
        setPageTitle("Unblock Time");
        break;
      default:
        setPageTitle("");
        break;
    }
  }, [path]);

  return (
    <>
      <Grid item xs={12} sm={12} md={12} className={classes.header}>
        <Grid container direction="row" style={{ paddingLeft: "1%" }}>
          <Grid item xs={5} className={classes.headerTitleContainer}>
            {path == "/admin" || path == "/" ? (
              <h1 className={classes.headerText}>Tyson Appointment Booking</h1>
            ) : (
              <h1 className={classes.headerText} onClick={() => (currentUser.isAdmin ? history.push("/admin") : history.push("/"))}>
                &#8678; Tyson Appointment Booking
              </h1>
            )}
          </Grid>
          <Grid item xs={6} className={classes.headerTitleContainer}>
            <h1 className={classes.titleText}>{pageTitle}</h1>
          </Grid>
          {userDetails.length !== 0 ? (
            <Grid item xs={1}>
              <AccountCircleIcon onClick={handleClick} className={classes.userIcon} />
              <Menu style={{ marginTop: "2.5rem" }} id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem> {userDetails.name}</MenuItem>
                <MenuItem> {userDetails.email}</MenuItem>
                {/* <MenuItem onClick={() => Logout()}>Logout</MenuItem> */}
              </Menu>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default connect(mapStateToProps)(Header);
