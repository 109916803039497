import * as actionTypes from "./actionTypes";
import serverAxios from "../../services/axios-instance";

const onGetEmployee = (employee) => {
  return {
    type: actionTypes.SET_EMPLOYEE,
    payload: employee,
  };
};

const onGetEmployeeTestCases = (testCases) => {
  return {
    type: actionTypes.SET_EMPLOYEE_TEST_CASES,
    testCases: testCases,
  };
};

const onGetEmployeeForAdmin = (employee) => {
  return {
    type: actionTypes.SET_EMPLOYEE_FOR_ADMIN,
    payload: employee,
  };
};

const onGetEmployeeForAdminTestCases = (testCases) => {
  return {
    type: actionTypes.SET_EMPLOYEE_FOR_ADMIN_TEST_CASES,
    testCases: testCases,
  };
};

export const onClearEmployeeDetails = () => {
  return {
    type: actionTypes.SET_EMPLOYEE,
    payload: [],
  };
};

export const onClearEmployeeForAdminDetails = () => {
  return {
    type: actionTypes.SET_EMPLOYEE_FOR_ADMIN,
    payload: [],
  };
};

export const onClearEmployeeForAdminTestCases = () => {
  return {
    type: actionTypes.SET_EMPLOYEE_FOR_ADMIN_TEST_CASES,
    testCases: [],
  };
};

export const onGetEmployeeOktaInformation = (employeeOktaInformation) => {
  return {
    type: actionTypes.SET_EMPLOYEE_OKTA_INFORMATION,
    employeeOktaInformation: employeeOktaInformation,
  };
};

export const getEmployeeForAdmin = (email) => {
  return (dispatch) => {
    let params = {
      params: {
        email: email,
      },
    };
    let oktaToken = JSON.parse(window.sessionStorage.getItem("okta-token-storage")).accessToken;
    serverAxios.defaults.headers.common.authorization = `Bearer ${oktaToken.accessToken}`;
    return new Promise((resolve, rej) => {
      serverAxios
        .get("/admin/employee-by-email", params)
        .then((result) => {
          // if (result.data.length == 0) {
          //   alert("User does not exist in database, Go to Pending Appointments tab in admin panel and add new team member to begin process workflow");
          // }
          dispatch(onGetEmployeeForAdmin(result.data[0]));
          resolve(result);
        })
        .catch((err) => {
          resolve(err);
        });
    });
  };
};

export const getEmployeeForAdminTestCases = (id) => {
  return (dispatch, getState) => {
    let employee = getState().employee.employeeForAdmin;
    let params = {
      params: {
        empID: id,
        canSchedule: 1,
      },
    };
    let oktaToken = JSON.parse(window.sessionStorage.getItem("okta-token-storage")).accessToken;
    serverAxios.defaults.headers.common.authorization = `Bearer ${oktaToken.accessToken}`;
    return new Promise((resolve, rej) => {
      serverAxios
        .get("/employee-test-cases", params)
        .then((result) => {
          dispatch(onGetEmployeeForAdminTestCases(result.data));
          resolve();
        })
        .catch((err) => {
          resolve(err);
        });
    });
  };
};

export const getEmployee = (email) => {
  return (dispatch) => {
    let oktaToken = JSON.parse(window.sessionStorage.getItem("okta-token-storage")).accessToken;
    serverAxios.defaults.headers.common.authorization = `Bearer ${oktaToken.accessToken}`;
    return new Promise((resolve, rej) => {
      serverAxios
        .get("/employee")
        .then((result) => {
          sessionStorage.setItem("email", result.data[0].email);
          dispatch(onGetEmployee(result.data[0]));
          resolve(result);
        })
        .catch((err) => {
          resolve(err);
        });
    });
  };
};

export const getEmployeeTestCases = () => {
  return (dispatch, getState) => {
    let employee = getState().employee.employee;
    // let email = employee ? (employee.email ? employee.email : process.env.REACT_APP_TEST_EMAIL) : process.env.REACT_APP_TEST_EMAIL;
    let params = {
      params: {
        empID: employee.empID,
        canSchedule: 1,
      },
    };
    let oktaToken = JSON.parse(window.sessionStorage.getItem("okta-token-storage")).accessToken;
    serverAxios.defaults.headers.common.authorization = `Bearer ${oktaToken.accessToken}`;
    return new Promise((resolve, rej) => {
      serverAxios
        .get("/employee-test-cases", params)
        .then((result) => {
          dispatch(onGetEmployeeTestCases(result.data));
          resolve();
        })
        .catch((err) => {
          resolve(err);
        });
    });
  };
};

export const postEmployeeRoleToBackend = () => {
  return (dispatch, getState) => {
    let oktaToken = JSON.parse(window.sessionStorage.getItem("okta-token-storage")).accessToken;
    serverAxios.defaults.headers.common.authorization = `Bearer ${oktaToken.accessToken}`;
    return new Promise((resolve, rej) => {
      serverAxios
        .post("/login")
        .then((result) => {
          if (result.status === 200) {
            resolve(result);
            // getEmployee()
          } else {
            resolve(result.status);
          }
        })
        .catch((err) => {
          resolve(err);
        });
    });
  };
};
