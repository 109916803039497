import * as actionTypes from "./actionTypes";
import serverAxios from "../../services/axios-instance";
import config from "../../config";

const onBookAppointment = (timeRange) => {
  return {
    type: actionTypes.BOOK_APPOINTMENT,
    timeRange: timeRange,
  };
};

const onSetTimeRanges = (timeRanges) => {
  return {
    type: actionTypes.SET_AVAILABLE_TIME_RANGES,
    timeRanges: timeRanges,
  };
};
const onSetBlockedTime = (timeRanges) => {
  return {
    type: actionTypes.SET_BLOCKED_TIME,
    timeRanges: timeRanges,
  };
};

export const onSetLoading = (value) => {
  return {
    type: actionTypes.SET_LOADING,
    loading: value,
  };
};

const hasConsentForm = (testCaseID) => {
  let oktaToken = JSON.parse(window.sessionStorage.getItem("okta-token-storage")).accessToken;
  serverAxios.defaults.headers.common.authorization = `Bearer ${oktaToken.accessToken}`;
  return new Promise((resolve, rej) => {
    serverAxios
      .get(`/appointment/testCasesDetails/${testCaseID}`)
      .then((result) => {
        let testCaseData = result.data;
        console.log("testCaseData: ", testCaseData);
        let hasConsentForm = false;
        if (testCaseData.bookingData) {
          hasConsentForm = JSON.parse(testCaseData.bookingData).consentFormID ? true : false;
        }
        resolve(hasConsentForm);
      })
      .catch((err) => {
        resolve(err);
      });
  });
};

const sendConsentForm = (email, testCaseID) => {
  console.log("sendConsentForm email: ", email);
  let oktaToken = JSON.parse(window.sessionStorage.getItem("okta-token-storage")).accessToken;
  serverAxios.defaults.headers.common.authorization = `Bearer ${oktaToken.accessToken}`;
  return new Promise((resolve, rej) => {
    let body = {
      agreementPayload: {
        fileInfos: [
          {
            libraryDocumentId: "CBJCHBCAABAAWTV5DuehCxaeIkX7QSwpFdjqNm9UHbVU",
          },
        ],
        name: `Covid consent form ${config.environment.environment}`,
        participantSetsInfo: [
          {
            memberInfos: [
              {
                email: email,
              },
            ],
            order: 1,
            role: "SIGNER",
          },
        ],
        signatureType: "ESIGN",
        state: "IN_PROCESS",
        type: "AGREEMENT",
      },
      bookingData: {
        id: testCaseID,
      },
    };
    serverAxios
      .post("/sendConsentForm", body)
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        resolve(err);
      });
  });
};

export const bookAppointment = (scheduleData) => {
  return (dispatch, getState) => {
    let employee = getState().employee.employee;
    let oktaInformation = getState().employee.oktaInformation;
    let email = employee.email;
    if (employee.roleID === 1) {
      email = scheduleData.email;
    }
    let oktaToken = JSON.parse(window.sessionStorage.getItem("okta-token-storage")).accessToken;
    serverAxios.defaults.headers.common.authorization = `Bearer ${oktaToken.accessToken}`;
    return new Promise((resolve, rej) => {
      serverAxios
        .post("/appointment", scheduleData)
        .then((result) => {
          if (result.status === 200) {
            console.log("bookAppointment result: ", result);
            dispatch(onBookAppointment);
            hasConsentForm(result.data.testCaseID).then((consentFormExists) => {
              console.log("consentFormExists: ", consentFormExists);
              if (!consentFormExists) {
                sendConsentForm(email, result.data.testCaseID).then((consentForm) => {
                  resolve(result.status);
                });
              } else {
                resolve(result.status);
              }
            });
            resolve(result.status);
          } else {
            alert("Appointment not scheduled");
            resolve(result.status);
          }
        })
        .catch((err) => {
          resolve(err);
        });
    });
  };
};

export const getTimeSlots = (selectedDate, beforeToday) => {
  return (dispatch, getState) => {
    let employee = getState().employee.employee;
    let params = {
      params: {
        startTime: selectedDate,
        resourceTypeID: 1,
      },
    };
    dispatch(onSetLoading(true));
    let oktaToken = JSON.parse(window.sessionStorage.getItem("okta-token-storage")).accessToken;
    serverAxios.defaults.headers.common.authorization = `Bearer ${oktaToken.accessToken}`;
    return new Promise((resolve, rej) => {
      if (beforeToday) {
        resolve();
      }
      serverAxios
        .get("/appointment/available-slots", params)
        .then((result) => {
          dispatch(onSetTimeRanges(result.data));
          resolve();
        })
        .catch((err) => {
          resolve(err);
        });
    });
  };
};

export const blockedTimeSlots = (selectedDate, beforeToday) => {
  return (dispatch, getState) => {
    let params = {
      params: {
        startTime: selectedDate,
        endTime: selectedDate,
        resourceTypeID: 1,
      },
    };
    dispatch(onSetLoading(true));
    let oktaToken = JSON.parse(window.sessionStorage.getItem("okta-token-storage")).accessToken;
    serverAxios.defaults.headers.common.authorization = `Bearer ${oktaToken.accessToken}`;
    return new Promise((resolve, rej) => {
      serverAxios
        .get("admin/appointment/reserved-time", params)
        .then((result) => {
          dispatch(onSetBlockedTime(result.data));
          resolve();
        })
        .catch((err) => {
          resolve(err);
        });
    });
  };
};
