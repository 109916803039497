//
import OktaAuth from "@okta/okta-auth-js";
import config from "../config";

export let authClient;

authClient = new OktaAuth({
    tokenManager: {
        storage: "sessionStorage",
        secure: true,
    },
    issuer: config.okta.issuer,
    clientId: config.okta.clientId,
    pkce: true,
    url: config.okta.url,
    redirectUri: config.okta.redirectUri, //window.location.origin, //config.okta.redirectUri,
});

export const scopes = {
    scopes: ["openid", "profile", "email", "phone", "groups", "CovidTestScheduling"],
    // scopes: ["openid", "profile", "email"],
};

export const loginWithRedirect = () => {
    window.sessionStorage.removeItem("user");
    authClient.token.getWithRedirect(scopes);
};
