// Override http config
module.exports.api = {
    url: 'https://8f0fdwu8p6.execute-api.us-east-1.amazonaws.com/prod',
};

module.exports.okta = {
    clientId: '0oa8onr84bJ8P2VBo357',
    url: 'https://tyson.okta.com/',
    issuer: 'https://tyson.okta.com/oauth2/default',
    redirectUri: 'https://tysoncovidtestself-scheduling.tyson.com/callback',
};


module.exports.environment = {
    environment: ''
}

module.exports.version = { number: '1.0' }
