// Override http config
module.exports.api = {
    url: 'https://tbd1w44cn5.execute-api.us-east-1.amazonaws.com/dev/',
};

module.exports.okta = {
    clientId: '0oaxzdqlq9sB7yzw60h7',
    url: 'https://tyson-dev.oktapreview.com/',
    issuer: 'https://tyson-dev.oktapreview.com/oauth2/default',
    redirectUri: 'https://tysoncovidtestself-scheduling-dev.tyson.com/callback',
};

module.exports.environment = {
    environment: 'dev'
}


module.exports.version = { number: '1.0' }
