import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Zoom from "@material-ui/core/Zoom";
import { Checkbox } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import CancelIcon from "@material-ui/icons/Cancel";
import ScheduleIcon from "@material-ui/icons/Schedule";
import moment from "moment";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import FlagIcon from "@material-ui/icons/Flag";
import swal from "sweetalert2";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import {
  getAllAppointments,
  cancelAppointment,
  getAppointmentsByStatus,
  getNonCompliantEmployees,
  flagToggleNonCompliantEmployee,
  getFollowUpEmployees,
  onUpdatePath,
  onSetLoading,
  getConsentFormStatus,
  addMemberToWorkflowProcess,
  addToWorkFlow,
  moveToTestingInProgress,
  testingCompleted,
  sendConsentForm,
} from "../../store/actions/index";
import NonCompliantTable from "./nonCompliantTable";
import Tooltip from "@material-ui/core/Tooltip";
import EmojiFlagsIcon from "@material-ui/icons/EmojiFlags";
import Loader from "../images/loading.gif";
import MaterialTable from "material-table";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import Clear from "@material-ui/icons/Clear";
import Search from "@material-ui/icons/Search";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Check from "@material-ui/icons/Check";
import FilterList from "@material-ui/icons/FilterList";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Save from "@material-ui/icons/Save";
import Remove from "@material-ui/icons/Remove";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import { TextField } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useStyles } from "./styling";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#00000029",
    color: "#000",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#fff",
    },
    "&:hover": {
      backgroundColor: "#cddffa",
      color: "white",
    },
  },
}))(TableRow);

const mapDispatchToProps = (dispatch) => {
  return {
    getAllAppointments: (value, offset) => dispatch(getAllAppointments(value, offset)),
    cancelAppointment: (value) => dispatch(cancelAppointment(value)),
    onGetAppointmentsByStatus: (status, type, offset) => dispatch(getAppointmentsByStatus(status, type, offset)),
    onGetNonCompliantEmployees: () => dispatch(getNonCompliantEmployees()),
    onFlagToggleNonCompliantEmployee: (empID, nonCompliant) => dispatch(flagToggleNonCompliantEmployee(empID, nonCompliant)),
    getFollowUpEmployees: () => dispatch(getFollowUpEmployees()),
    onPathChange: (path) => dispatch(onUpdatePath(path)),
    onLoading: (value) => dispatch(onSetLoading(value)),
    onGetConsentFormStatus: () => dispatch(getConsentFormStatus()),
    addMemberToWorkflowProcess: (data) => dispatch(addMemberToWorkflowProcess(data)),
    addingToWorkflow: (eId) => dispatch(addToWorkFlow(eId)),
    moveToTestingInProgress: (testId) => dispatch(moveToTestingInProgress(testId)),
    testingCompleted: (testId) => dispatch(testingCompleted(testId)),
    onSendConsentForm: (email) => dispatch(sendConsentForm(email)),
  };
};

const mapStateToProps = (state) => {
  return {
    employee: state.employee.employee,
    employeeTestCases: state.employee.employeeTestCases,
    appointments: state.appointment.appointments,
    nonCompliantEmployees: state.admin.nonCompliantEmployees,
    loading: state.appointment.loading,
    employeeOktaInformation: state.employee.employeeOktaInformation,
    currentUser: state.employee.currentUser,
  };
};

const PastRecords = (props) => {
  const {
    getAllAppointments,
    getFollowUpEmployees,
    appointments,
    cancelAppointment,
    onGetAppointmentsByStatus,
    tabPosition,
    onGetNonCompliantEmployees,
    nonCompliantEmployees,
    onFlagToggleNonCompliantEmployee,
    onPathChange,
    onLoading,
    loading,
    onGetConsentFormStatus,
    addMemberToWorkflowProcess,
    addingToWorkflow,
    moveToTestingInProgress,
    testingCompleted,
    employee,
    employeeOktaInformation,
    onSendConsentForm,
    currentUser,
  } = props;
  const [selectedStatus, setSelectedStatus] = useState("");
  const [searchedValue, setSearchValue] = useState("");
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const classes = useStyles();
  const history = useHistory();
  let path = window.location.pathname;
  const [emailOfNewMember, setEmailOfNewMember] = React.useState("");
  const [phoneNoOfNewMember, setPhoneNoOfNewMember] = useState("");
  const [firstNameOfNewMember, setFirstNameOfNewMember] = useState("");
  const [lastNameOfNewMember, setLastNameOfNewMember] = useState("");
  const [employeeNumber, setEmployeeNumber] = useState("");
  const [showModal, setShowModal] = React.useState(false);

  const loadingImage = (
    <div
      style={{
        backgroundColor: "#fff",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "75vh",
        marginTop: "-40px",
        padding: "0px",
      }}
    >
      <img src={Loader} alt="loading" height="100vh" />
    </div>
  );

  const smallLoadingImage = (
    <div
      style={{
        backgroundColor: "#fff",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "0px",
        padding: "0px",
      }}
    >
      <img src={Loader} alt="loading" height="50px" width="50px" />
    </div>
  );

  useEffect(() => {
    onPathChange(path);
  }, [history]);

  useEffect(() => {
    if (currentUser.isAdmin) {
      onLoading(true);
      switch (tabPosition) {
        case 1:
          getAllAppointments().then(() => {
            onLoading(false);
            onGetConsentFormStatus().then(() => { });
          });
          break;
        case 2:
          onGetAppointmentsByStatus([
            { status: 7, type: 2 },
            { status: "BOOKING_PENDING", type: 1 },
          ]).then(() => {
            onLoading(false);
          });
          break;
        case 3:
          getFollowUpEmployees().then(() => {
            onLoading(false);
          });
          break;
        case 4:
          onGetNonCompliantEmployees().then(() => {
            onLoading(false);
          });
          break;
        default:
          onLoading(false);
          break;
      }
    }

    setSelectedStatus("");
    setSearchValue("");
  }, [tabPosition]);

  const sort_by_key = (array, key, asc) => {
    return array.sort((a, b) => {
      let x = a[key];
      let y = b[key];
      return asc ? (x < y ? -1 : x > y ? 1 : 0) : x < y ? 1 : x > y ? -1 : 0;
    });
  };
  let gridData = filteredAppointments && searchedValue !== "" ? filteredAppointments : appointments;
  gridData = sort_by_key(gridData, "testCaseID", false);
  const status = (id) => {
    switch (id) {
      case 1:
        return "Employee identified for testing";
      case 2:
        return "Employee notified, employee action pending";
      case 3:
        return "Multiple reminder sent, need manual action";
      case 4:
        return "Employee booking confirmed";
      case 5:
        return "Testing in progress";
      case 6:
        return "Testing complete, result uploaded";
      case 7:
        return "Appointment cancelled";
      default:
        break;
    }
  };

  const handleCancelAppointment = (id, empID, text) => {
    let data = {
      testCaseID: id,
      resourceTypeID: 1,
      empID: empID,
    };
    onLoading(true);
    cancelAppointment(data).then((status) => {
      switch (tabPosition) {
        case 1:
          getAllAppointments().then(() => {
            onLoading(false);
            onGetConsentFormStatus().then(() => { });
          });
          break;
        case 2:
          onGetAppointmentsByStatus([
            { status: 2, type: 2 },
            { status: 7, type: 2 },
          ]).then(() => {
            onLoading(false);
          });
          break;
        case 3:
          getFollowUpEmployees().then(() => {
            onLoading(false);
          });
          break;
        case 4:
          onGetNonCompliantEmployees().then(() => {
            onLoading(false);
          });
          break;
        default:
          onLoading(false);
          break;
      }
      if (status === 200) {
        swal.fire({
          icon: "success",
          title: `${text} Cancelled Successfully`,
          timer: 2000,
        });
      }
    });
  };

  const filterAppointmentsBySearch = (searchValue) => {
    let filteredAppointments = [];
    if (gridData) {
      filteredAppointments = gridData.filter((ap) => {
        return ap.empName.toLowerCase().includes(searchValue.toLowerCase());
      });
    }
    setFilteredAppointments(filteredAppointments);
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
    onLoading(true);
    onGetAppointmentsByStatus([{ status: event.target.value, type: 1 }]).then(() => {
      onLoading(false);
      onGetConsentFormStatus().then(() => {
        filterAppointmentsBySearch(searchedValue);
      });
    });
  };

  const handleNonCompliantClick = (empID, nonCompliant) => {
    onFlagToggleNonCompliantEmployee(empID, nonCompliant).then((status) => {
      if (status === 200) {
        if (nonCompliant === 0) {
          swal.fire({
            icon: "success",
            title: "Employee Unflagged successfully",
            timer: 2000,
          });
        } else {
          swal.fire({
            icon: "success",
            title: "Employee flagged successfully",
            timer: 2000,
          });
        }
        onLoading(true);
        switch (tabPosition) {
          case 1:
            getAllAppointments().then(() => {
              onLoading(false);
              filterAppointmentsBySearch(searchedValue);
              onGetConsentFormStatus().then(() => { });
            });
            break;
          case 2:
            onGetAppointmentsByStatus([
              { status: 2, type: 2 },
              { status: 7, type: 2 },
            ]).then(() => {
              onLoading(false);
            });
            break;
          default:
            getAllAppointments().then(() => {
              filterAppointmentsBySearch(searchedValue);
              onLoading(false);
              onGetConsentFormStatus().then(() => { });
            });
            break;
        }
        onGetNonCompliantEmployees();
      } else {
        swal.fire({
          icon: "warning",
          title: "An error ocurred",
          timer: 2000,
        });
      }
    });
  };

  const loadMoreAppointments = (offset) => {
    console.log("SelectedStatus: ", selectedStatus);
    onLoading(true);
    switch (tabPosition) {
      case 1:
        if (selectedStatus !== "") {
          onGetAppointmentsByStatus([{ status: selectedStatus, type: 1, offset: offset }]).then(() => {
            onLoading(false);
            onGetConsentFormStatus().then(() => {
              filterAppointmentsBySearch(searchedValue);
            });
          });
        } else {
          getAllAppointments(undefined, offset).then(() => {
            onLoading(false);
            filterAppointmentsBySearch(searchedValue);
            onGetConsentFormStatus().then(() => { });
          });
        }
        break;
      case 2:
        const BOOKING_PENDING_STATUS = [1, 2, 3];
        let status7Offset = gridData.filter((data) => data.statusID === 7);
        let statusBookingPendingOffset = gridData.filter((data) => BOOKING_PENDING_STATUS.includes(data.statusID));
        onGetAppointmentsByStatus(
          [
            { status: 7, type: 2, offset: status7Offset.length },
            {
              status: "BOOKING_PENDING",
              type: 1,
              offset: statusBookingPendingOffset.length,
            },
          ],
          offset
        ).then(() => {
          onLoading(false);
        });
        break;
      case 3:
        getFollowUpEmployees(offset).then(() => {
          onLoading(false);
        });
        break;
      case 4:
        onGetNonCompliantEmployees().then(() => {
          onLoading(false);
        });
        break;
      default:
        onLoading(false);
        break;
    }
  };

  const cancelAppointmentPopUp = (id, empID, text) => {
    swal
      .fire({
        title: `Are you sure you want to cancel this ${text} ?`,
        icon: "warning",
        confirmButtonText: `Yes`,
        showCancelButton: true,
      })
      .then((result) => {
        if (result.isConfirmed === true) {
          handleCancelAppointment(id, empID, text);
        }
      });
  };
  const convertGMTToCSTTime = (gmtTime) => {
    var m = moment.utc(gmtTime);
    m.tz("America/Chicago");
    return m.format("hh:mm A");
  };
  const convertGMTToCSTDate = (gmtTime) => {
    var m = moment.utc(gmtTime);
    m.tz("America/Chicago");
    return m.format("YYYY-MM-DD");
  };

  let columns = (data) => {
    if (tabPosition === 1) {
      return [
        {
          title: "Employee Name",
          field: "empName",
          render: (data) => <span>{data.empName}</span>,
        },
        {
          title: "Schedule Date",
          field: "scheduleDate",
          render: (data) => (data.startTime ? <span>{convertGMTToCSTDate(data.startTime)}</span> : null),
        },
        {
          title: "Start Time",
          field: "startTime",
          render: (data) => (data.startTime ? <span>{convertGMTToCSTTime(data.startTime)}</span> : null),
        },
        {
          title: "Status",
          field: "statusID",
          render: (data) => <span>{status(data.statusID)}</span>,
        },
        {
          title: "Consent Form",
          field: "consentFormStatus",
          render: (data) => (data.consentFormStatus ? <span>{data.consentFormStatus}</span> : smallLoadingImage),
        },
      ];
    } else if (tabPosition === 2) {
      return [
        {
          title: "Employee Name",
          field: "empName",
          render: (data) => <span>{data.empName}</span>,
        },
        {
          title: "Status",
          field: "statusID",
          render: (data) => <span>{status(data.statusID)}</span>,
        },
        {
          title: "Email",
          customSort: (a, b) => a.email.length - b.email.length,
          render: (data) => <span style={{ textAlign: "center" }}>{data.email.toLowerCase()} </span>,
        },
        {
          title: "Acquaint",
          render: (data) => <span style={{ textAlign: "center" }}>{data.numOfReminderSent} </span>,
        },
      ];
    }
  };



  const handleTestComplete = (row) => {
    onLoading(true);
    testingCompleted(row.testCaseID).then((result) => {
      if (result.status === 200) {
        getAllAppointments().then(() => {
          onLoading(false);
          onGetConsentFormStatus().then(() => { });
        });
      }
    });
  };

  const handleTestInProgress = (row) => {
    onLoading(true);
    moveToTestingInProgress(row.testCaseID).then((result) => {
      if (result.status === 200) {
        getAllAppointments().then(() => {
          onLoading(false);
          onGetConsentFormStatus().then(() => { });
        });
      }
    });
  };

  const handleSendReminder = (email) => {
    swal
      .fire({
        title: `Are you sure you want to re-send the consent form to ${email} ?`,
        icon: "warning",
        confirmButtonText: `Yes`,
        showCancelButton: true,
      })
      .then((result) => {
        if (result.isConfirmed === true) {
          onSetLoading(true);
          onSendConsentForm(email).then((res) => {
            onSetLoading(false);
            swal.fire({
              icon: "success",
              title: "Consent form sent",
              timer: 2000,
            });
          });
        }
      });
  };

  const actions = (row) => {
    if (tabPosition === 1) {
      return [
        (row) =>
          row.statusID === 5
            ? {
              icon: () => <CheckCircleOutlineIcon style={{ fontSize: "21px" }} />,
              tooltip: "Mark Test Completed",
              onClick: (event, row) => handleTestComplete(row),
            }
            : undefined,
        (row) =>
          row.statusID === 6
            ? {
              icon: () => <CheckCircleOutlineIcon style={{ fontSize: "21px", color: "green" }} />,
              tooltip: "Test already completed",
              onClick: (event, row) =>
                swal.fire({
                  icon: "success",
                  title: "Test Already Completed",
                  timer: 2000,
                }),
            }
            : undefined,

        (row) =>
          row.statusID === 4 || row.statusID === 7 || row.statusID === 1 || row.statusID === 3
            ? {
              icon: () => <CancelIcon style={{ fontSize: "21px" }} />,
              tooltip: "Cancel",
              onClick: (event, row) => cancelAppointmentPopUp(row.testCaseID, row.empID, "Appointment"),
            }
            : undefined,

        (row) =>
          row.statusID !== 5 && row.statusID !== 6
            ? {
              icon: () => (row.statusID === 4 || row.statusID === 7 ? <RotateLeftIcon style={{ fontSize: "21px" }} /> : <ScheduleIcon style={{ fontSize: "21px" }} />),
              tooltip: row.statusID === 4 || row.statusID === 7 ? "Reschedule" : "Schedule",
              onClick: (event, row) =>
                row.statusID === 4 || row.statusID === 7
                  ? history.push("/reschedule", {
                    rowData: row,
                    empID: row.empID,
                    email: row.email,
                  })
                  : history.push("/schedule", { rowData: row }),
            }
            : null,

        (row) =>
          row.statusID === 2
            ? {
              icon: () => <CancelIcon style={{ fontSize: "21px" }} />,
              tooltip: "Cancel Workflow",
              onClick: (event, row) => cancelAppointmentPopUp(row.testCaseID, row.empID, "Workflow"),
            }
            : undefined,

        (row) =>
          row.statusID === 4
            ? {
              icon: () => <TimelapseIcon style={{ fontSize: "21px" }} />,
              tooltip: "Mark process as in progress",
              onClick: (event, row) => handleTestInProgress(row),
            }
            : undefined,

        (row) => ({
          icon: () => <FlagIcon style={row.nonCompliant === 1 ? { color: "red", fontSize: "21px" } : { color: "black", fontSize: "21px" }} />,
          tooltip: row.nonCompliant !== 1 ? "Flag" : "Unflag ",
          onClick: (event, row) => {
            row.nonCompliant === 1 ? handleNonCompliantClick(row.empID, 0) : handleNonCompliantClick(row.empID, 1);
          },
        }),
        (row) =>
          row.statusID !== 5 && row.statusID !== 6
            ? {
              icon: () => <NotificationImportantIcon style={{ fontSize: "21px" }} />,
              tooltip: "Re-send consent form",
              onClick: (event, row) => handleSendReminder(row.email),
            }
            : undefined,
      ];
    } else if (tabPosition === 2) {
      return [
        {
          icon: () => <NotificationImportantIcon style={{ fontSize: "21px" }} />,
          tooltip: "Re-send consent form",
          onClick: (event, row) => handleSendReminder(row.email),
        },
        {
          icon: () => <ScheduleIcon style={{ fontSize: "21px" }} />,
          tooltip: "Schedule",
          onClick: (event, row) => history.push("/schedule", { rowData: row }),
        },
        (row) => ({
          icon: () => <FlagIcon style={row.nonCompliant === 1 ? { color: "red", fontSize: "21px" } : { color: "black", fontSize: "21px" }} />,
          tooltip: row.nonCompliant !== 1 ? "Flag" : "Unflag ",
          onClick: (event, row) => {
            row.nonCompliant === 1 ? handleNonCompliantClick(row.empID, 0) : handleNonCompliantClick(row.empID, 1);
          },
        }),
      ];
    }
  };
  const handleAddToWorkflow = () => {
    onLoading(true);
    if (emailOfNewMember == "" || firstNameOfNewMember == "" || lastNameOfNewMember == "" || phoneNoOfNewMember == "" || employeeNumber == "") {
      swal.fire({
        icon: "info",
        title: "Enter data in all the fields",
        timer: 3000,
      });
      onLoading(false);
    } else {
      let newMemberData = {
        email: emailOfNewMember,
        firstName: firstNameOfNewMember,
        lastName: lastNameOfNewMember,
        phoneNumber: phoneNoOfNewMember,
        employeeNumber: employeeNumber,
      };
      addMemberToWorkflowProcess(newMemberData).then((result) => {
        if (result.status === 200) {
          addingToWorkflow(result.data.empID).then((result) => {
            if (result.status === 200 && result.data.isSuccess === -1) {
              onLoading(false);
              setEmailOfNewMember("");
              setFirstNameOfNewMember("");
              setLastNameOfNewMember("");
              setPhoneNoOfNewMember("");
              setEmployeeNumber("");
              swal.fire({
                icon: "info",
                title: "Employee already exist in workflow",
                timer: 3000,
              });
            } else if (result.status === 200 && result.data.isSuccess === 1) {
              onLoading(false);
              setEmailOfNewMember("");
              setFirstNameOfNewMember("");
              setLastNameOfNewMember("");
              setPhoneNoOfNewMember("");
              setEmployeeNumber("");
              setShowModal(false);
              setEmailOfNewMember("");
              swal.fire({
                icon: "success",
                title: "Employee added to workflow successfully",
                timer: 3000,
              });
            }
          });
        }
      });
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <Grid container direction="row" className={classes.searchContainer}>
          <Grid item xs={4}>
            {tabPosition === 1 ? (
              <FormControl className={classes.select}>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select labelId="demo-simple-select-label" id="demo-simple-select" value={selectedStatus} onChange={handleStatusChange}>
                  <MenuItem value={""}>All</MenuItem>
                  <MenuItem value={"BOOKING_PENDING"}>Booking pending</MenuItem>
                  <MenuItem value={"OPEN"}>Booking confirmed</MenuItem>
                  <MenuItem value={"CLOSED"}>Testing complete</MenuItem>
                </Select>
              </FormControl>
            ) : undefined}
          </Grid>
          <Grid item>
            {tabPosition === 1 ? (
              <Grid>
                <Tooltip title="Schedule a new appointment">
                  <Button onClick={() => history.push("/schedule")} className={classes.addButton}>
                    <AddIcon />
                    Add New Appointment
                  </Button>
                </Tooltip>{" "}
                <Tooltip title="Block Time off Calendar">
                  <Button onClick={() => history.push("/block")} className={classes.addButton}>
                    <AddIcon />
                    Block Time
                  </Button>
                </Tooltip>
                <Tooltip title="Unblock Time off Calendar">
                  <Button onClick={() => history.push("/unblock")} className={classes.addButton}>
                    <AddIcon />
                    Unblock Time
                  </Button>
                </Tooltip>
              </Grid>
            ) : null}
          </Grid>
          <Dialog open={showModal} onClose={() => setShowModal(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            {loading ? (
              <img src={Loader} alt="loading" height="150vh" />
            ) : (
              <>
                <DialogTitle id="alert-dialog-title">{"Enter employee details below to start a workflow process and send notification to schedule testing appointment"}</DialogTitle>
                <DialogContent>
                  <TextField id="outlined-basic" className={classes.newMemberField} fullWidth={true} value={emailOfNewMember} onChange={(e) => setEmailOfNewMember(e.target.value)} label="Email Address" variant="outlined" />
                  <TextField id="outlined-basic" className={classes.newMemberField} fullWidth={true} value={employeeNumber} onChange={(e) => setEmployeeNumber(e.target.value)} label="Employee Number" variant="outlined" />
                  <TextField id="outlined-basic" className={classes.newMemberField} fullWidth={true} value={firstNameOfNewMember} onChange={(e) => setFirstNameOfNewMember(e.target.value)} label="First Name" variant="outlined" />
                  <TextField id="outlined-basic" className={classes.newMemberField} fullWidth={true} value={lastNameOfNewMember} onChange={(e) => setLastNameOfNewMember(e.target.value)} label="Last Name" variant="outlined" />
                  <TextField id="outlined-basic" className={classes.newMemberField} type="number" fullWidth={true} value={phoneNoOfNewMember} onChange={(e) => setPhoneNoOfNewMember(e.target.value)} label="Phone Number" variant="outlined" />
                </DialogContent>
                <DialogActions>
                  <Button className={classes.memberButton} onClick={() => handleAddToWorkflow()} color="primary" autoFocus>
                    Start Process
                  </Button>
                </DialogActions>
              </>
            )}
          </Dialog>
        </Grid>
      </Grid>

      {tabPosition === 2 ? (
        <Grid container direction="row" justify="space-between">
          <Grid item xs={2} className={classes.addMember}>
            <Tooltip TransitionComponent={Zoom} arrow title="Add a new team member to begin workflow process. Member will get notification email to schedule a testing appointment">
              <Button onClick={() => setShowModal(true)} style={{ padding: 10, color: "white" }} startIcon={<AddIcon />}>
                Add New Team Member
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      ) : null}

      {[1, 2].includes(tabPosition) ? (
        loading ? (
          loadingImage
        ) : (
          <div style={{ marginTop: "25px" }}>
            <MaterialTable
              title={tabPosition === 1 ? "All Appointments" : "Pending Appointments"}
              key={gridData.empID}
              data={
                tabPosition === 1
                  ? gridData.filter((row) => row.statusID !== 7)
                  : tabPosition === 2 ?
                    gridData.filter(
                      // pending appointment table here (withoutt box checked)
                      (row) => row.statusID !== 4 && row.statusID !== 5 && row.statusID !== 6
                    )
                    : null
              }
              columns={columns(gridData)}
              icons={{
                Check: Check,
                Clear: Clear,
                DetailPanel: ChevronRight,
                Export: SaveAlt,
                Filter: FilterList,
                FirstPage: FirstPage,
                LastPage: LastPage,
                NextPage: ChevronRight,
                PreviousPage: ChevronLeft,
                Search: Search,
                ThirdStateCheck: Remove,
                Edit: Edit,
                Delete: Delete,
                SaveAlt: SaveAlt,
                Save: Save,
                SortArrow: ArrowDownwardIcon,
                FlagIcon: FlagIcon,
                NotificationImportantIcon: NotificationImportantIcon,
                CancelIcon: CancelIcon,
                RotateLeftIcon: RotateLeftIcon,
                ScheduleIcon: ScheduleIcon,
                EmojiFlagsIcon: EmojiFlagsIcon,
                CheckCircleOutlineIcon: CheckCircleOutlineIcon,
              }}
              actions={actions(gridData)}
              options={{
                pageSize: 5,
                pageSizeOptions: [10, 20, 50],
                paging: true,
                sorting: true,
                search: true,
                headerStyle: { fontWeight: "bold" },
                actionsColumnIndex: -1,
                emptyRowsWhenPaging: false,
              }}
            />
          </div>
        )
      ) : tabPosition === 3 ? undefined : tabPosition === 4 ? (
        loading ? (
          loadingImage
        ) : (
          <NonCompliantTable nonCompliantEmployees={nonCompliantEmployees} />
        )
      ) : undefined}
      {loading ? undefined : (
        <Grid item xs={12}>
          <Grid container direction="row">
            <Grid item xs={3} style={{ textAlign: "left" }}>
              {tabPosition === 1 ? (
                <span></span>
              ) : null}
              {/* <Tooltip title="Load the next appointments">
                <Button onClick={() => loadMoreAppointments(appointments ? appointments.length : 0)} className={classes.addButton}>
                  <RotateLeftIcon />
                Load More Appointments
              </Button>
              </Tooltip> */}
            </Grid>
            <Grid item xs={3} style={{ textAlign: "left" }}>
              {/* <p> Showing {gridData.filter((row) => row.statusID !== 7).length} appointments</p> */}
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(PastRecords);
