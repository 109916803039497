import * as actionTypes from "../actions/actionTypes";

const initialState = {
  employee: [],
  employeeForAdmin: [],
  employeeForAdminTestCases: [],
  employeeTestCases: [],
  logging: false,
  employeeOktaInformation: {},
  currentUser: {
    email: "",
    isAdmin: false,
    firstName: "",
    lastName: "",
    isHR: false,
    isRegularUser: false,
    isValid: false,
  },
};

export const EmployeeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_EMPLOYEE:
      // console.log("action.payload: ", action.payload)
      return {
        ...state,
        employee: action.payload,
        logging: true,
      };
    case actionTypes.SET_EMPLOYEE_TEST_CASES:
      return {
        ...state,
        employeeTestCases: action.testCases,
      };
    case actionTypes.SET_EMPLOYEE_FOR_ADMIN:
      return {
        ...state,
        employeeForAdmin: action.payload,
        logging: true,
      };
    case actionTypes.SET_EMPLOYEE_FOR_ADMIN_TEST_CASES:
      return {
        ...state,
        employeeForAdminTestCases: action.testCases,
      };
    case actionTypes.SET_EMPLOYEE_OKTA_INFORMATION:
      return {
        ...state,
        employeeOktaInformation: action.employeeOktaInformation,
        currentUser: {
          email: action.employeeOktaInformation.email,
          isAdmin: action.employeeOktaInformation.CovidSelfTestScheduling ? action.employeeOktaInformation.CovidSelfTestScheduling.includes("CovidSelfTestAdmin") : false,
          firstName: action.employeeOktaInformation.given_name,
          lastName: action.employeeOktaInformation.family_name,
          isHR: action.employeeOktaInformation.CovidSelfTestScheduling ? action.employeeOktaInformation.CovidSelfTestScheduling.includes("CovidSelfTestHR") : false,
          isRegularUser: action.employeeOktaInformation.CovidSelfTestScheduling ? !action.employeeOktaInformation.CovidSelfTestScheduling.includes("CovidSelfTestAdmin") && !action.employeeOktaInformation.CovidSelfTestScheduling.includes("CovidSelfTestHR") : true,
          isValid: true,
        },
      };
    default:
      return state;
  }
};
