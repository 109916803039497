import "date-fns";
import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers";
import { Button } from "@material-ui/core";
import swal from "sweetalert2";
import { connect } from "react-redux";
import { postFollowUp } from "../../store/actions";
import { useStyles } from "./styling";
import "./fullCalendar.css";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

const mapDispatchToProps = (dispatch) => {
  return {
    postFollowUp: (data) => dispatch(postFollowUp(data)),
  };
};

const FollowUpForm = (props) => {
  let today8AM = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), "08", "00", "00");
  const [followUpTitle, setFollowUpTitle] = useState("");
  const [followUpContent, setFollowUpContent] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [selectedDate, setSelectedDate] = useState(today8AM);
  const [priorityLevel, setPriorityLevel] = useState("");

  const classes = useStyles();

  useEffect(() => {
    // let newDate = new Date(props.eventData.eventDate + "UTC")
    // console.log("newDate: ", newDate)
    // moment.utc(props.eventData.eventDate).format("YYYY-MM-DD HH:mm")
    if (props.eventData && props.eventData.eventDate) {
      setSelectedDate(moment.utc(props.eventData.eventDate).format("YYYY-MM-DD HH:mm"));
    }

    // setSelectedDate(newDate)
    if (props.eventData !== "" && props.eventData.bookingNotes !== null) {
      setEmployeeName(props.eventData.bookingNotes.employeeName);
      setFollowUpTitle(props.eventData.bookingNotes.title);
      setFollowUpContent(props.eventData.bookingNotes.content);
      if (props.eventData.bookingNotes.priorityLevel) {
        setPriorityLevel(props.eventData.bookingNotes.priorityLevel);
      }
    } else if (props.eventData == "") {
      setEmployeeName("");
      setFollowUpTitle("");
      setFollowUpContent("");
      setPriorityLevel("");
      setSelectedDate(today8AM);
    }
  }, [props]);
  const handleFollowUpTitleChange = (value) => {
    setFollowUpTitle(value);
  };
  const handleFollowUpContentChange = (value) => {
    setFollowUpContent(value);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const createFollowUpNote = () => {
    // console.log("selectedDate: ", selectedDate);
    if (!employeeName) {
      swal.fire({
        icon: "warning",
        title: "A follow up should have a employee name!",
        showConfirmButton: true,
      });
      return;
    }
    if (!followUpTitle) {
      swal.fire({
        icon: "warning",
        title: "A follow up should have a title!",
        showConfirmButton: true,
      });
      return;
    }

    if (!selectedDate) {
      swal.fire({
        icon: "warning",
        title: "A follow up should have a date!",
        showConfirmButton: true,
      });
      return;
    }
    if (!priorityLevel) {
      swal.fire({
        icon: "warning",
        title: "A follow up should have a priority!",
        showConfirmButton: true,
      });
      return;
    }
    let date = new Date(selectedDate + ":00 GMT-0500");
    let postData = {
      startTime: date.toISOString(),
      endTIme: date.toISOString(),
      title: followUpTitle,
      content: followUpContent,
      employeeName: employeeName,
      priorityLevel: priorityLevel,
    };
    // console.log("postData: ", postData);
    props.postFollowUp(postData).then((status) => {
      if (status === 200) {
        swal.fire({
          icon: "success",
          title: "Follow up created !",
          showConfirmButton: true,
          timer: 2000,
        });
        props.showModal(false);
        props.getFollowUpEmployees();
        setEmployeeName("");
        setFollowUpTitle("");
        setFollowUpContent("");
      }
    });
  };

  const updateFollowUpNote = () => {
    if (!employeeName) {
      swal.fire({
        icon: "warning",
        title: "A follow up should have a employee name!",
        showConfirmButton: true,
      });
      return;
    }
    if (!followUpTitle) {
      swal.fire({
        icon: "warning",
        title: "A follow up should have a title!",
        showConfirmButton: true,
      });
      return;
    }

    if (!selectedDate) {
      swal.fire({
        icon: "warning",
        title: "A follow up should have a date!",
        showConfirmButton: true,
      });
      return;
    }
    if (!priorityLevel) {
      swal.fire({
        icon: "warning",
        title: "A follow up should have a priority!",
        showConfirmButton: true,
      });
      return;
    }
    swal
      .fire({
        title: "Are you sure you want to edit this followup ?",
        icon: "warning",
        confirmButtonText: `Yes`,
        showCancelButton: true,
      })
      .then((result) => {
        if (result.isConfirmed === true) {
          let date = new Date(selectedDate + ":00 GMT-0500");
          let postData = {
            calendarID: props.eventData.calendarID,
            startTime: date.toISOString(),
            endTIme: date.toISOString(),
            title: followUpTitle,
            content: followUpContent,
            employeeName: employeeName,
            priorityLevel: priorityLevel,
          };
          // console.log("postData: ", postData);
          props.postFollowUp(postData).then((status) => {
            if (status === 200) {
              swal.fire({
                icon: "success",
                title: "Follow up edited !",
                showConfirmButton: true,
              });
              props.showModal(false);
              props.getFollowUpEmployees();
              setEmployeeName("");
              setFollowUpTitle("");
              setFollowUpContent("");
            }
          });
        }
      });
  };

  return (
    <>
      <Grid container className={classes.followUpContainer}>
        <Grid item xs={12}>
          <Grid item xs={12} className={classes.formRow} style={{ textAlign: "center" }}>
            {props.addOrEdit === "add" ? <h2>Create follow up note</h2> : <h2>Edit follow up note</h2>}
          </Grid>
          <Grid item xs={12} className={classes.formRow}>
            <TextField id="employee_name" label="Employee Name" multiline rowsMax={1} value={employeeName} onChange={(e) => setEmployeeName(e.target.value)} variant="outlined" className={classes.formTextField} />
          </Grid>
          <Grid item xs={12} className={classes.formRow}>
            <TextField id="title" label="Title" multiline rowsMax={1} value={followUpTitle} onChange={(e) => handleFollowUpTitleChange(e.target.value)} variant="outlined" className={classes.formTextField} />
          </Grid>

          <Grid item xs={12} className={classes.formRow}>
            <TextField id="content" label="Content" multiline rows={4} value={followUpContent} onChange={(e) => handleFollowUpContentChange(e.target.value)} variant="outlined" className={classes.formTextField} />
          </Grid>
          <Grid item xs={12} className={classes.formRow}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">Priority Level</InputLabel>
              <Select labelId="demo-simple-select-label" label="Priority Level" value={priorityLevel} onChange={(e) => setPriorityLevel(e.target.value)}>
                <MenuItem value={"Call"}>Call</MenuItem>
                <MenuItem value={"Attempted"}>Attempted</MenuItem>
                <MenuItem value={"Completed"}>Completed</MenuItem>
              </Select>
            </FormControl>{" "}
          </Grid>
          <Grid container direction="row">
            <Grid item xs={6} className={classes.formRow}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Date"
                    autoOk={true}
                    className={classes.formDateField}
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6} className={classes.formRow}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">
                  <KeyboardTimePicker
                    margin="normal"
                    id="time-picker"
                    label="Time"
                    autoOk={true}
                    value={selectedDate}
                    onChange={handleDateChange}
                    style={{ marginLeft: "10px" }}
                    className={classes.formDateField}
                    KeyboardButtonProps={{
                      "aria-label": "change time",
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>

          <Grid item xs={12} className={classes.formRow}>
            {props.addOrEdit == "add" ? (
              <Button className={classes.formButton} onClick={() => createFollowUpNote()}>
                Create Note
              </Button>
            ) : (
              <Button className={classes.formButton} onClick={() => updateFollowUpNote()}>
                Update Note
              </Button>
            )}
            <Button className={classes.cancelButton} onClick={() => props.showModal(false)}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default connect(null, mapDispatchToProps)(FollowUpForm);
